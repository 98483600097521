body { text-align: center; margin: 0 auto; padding: 0; font-size: 17px; }
body.green { background: #E6EADB; }

.wrapper { width: 90%; margin: 0 5%; max-width: 1600px; margin: 0 auto; text-align: left; }
.wide-wrapper { width: 90%; margin: 0 5%; max-width: 1600px; margin: 0 auto; text-align: left; }
.top-margin { margin-top: 50px; }

.main { text-align: left; width: 70%; float: right; padding: 10px 0 50px 0%;  border-bottom: 1px solid #ddd; }
.main-no-sidebar { max-width: 720px; margin: 0 auto; float: none; width: 100%; padding: 0; }

@media (min-width: 768px) and (max-width:992px) {

	.wrapper,.wide-wrapper { width: 90%; margin: 0 5%; }

	
}

@media screen and (max-width: 768px) {

	.wrapper,.wide-wrapper { width: 90%; margin: 0 5%; }

  	.main { width: 100%; padding: 0; border-bottom: 0; float: none; box-shadow: none; -moz-box-shadow: none; -webkit-box-shadow: none; }
}
