.homepage {
	
	.splash { margin: 30px 0; padding: 0; border-bottom: 1px solid #ddd; text-align: center; display: flex; gap: 30px; justify-content: center; align-items: center;
		.text { max-width: 800px;
			h1 { font-size: 62px; margin-top: 0; font-weight: 700;  text-wrap: balance;  }
			p { font-size: 23px; font-weight: 400; line-height: 165%; text-wrap: balance; }
			a { color: $primary-color; text-decoration: underline; }
		}
		img { max-width: 500px; }

	}
	
	h3.quote { font-family: "Young Serif", serif; font-weight: 400; font-size: 36px; margin: 0 auto; padding: 5%; text-align: center; max-width: 1020px; width: 90%; text-wrap: balance; }
	
}

@media  (max-width:1400px) {
  
	.homepage .splash .text {
		h1 { font-size: 48px; }
	}
}


@media  (max-width:1150px) {
  
	.homepage .splash {
		img { max-width: 350px; }
		.text {
			h1 { font-size: 40px; }
			p { font-size: 19px; }
		}
	}

	.homepage h3.quote { font-size: 24px;  }

}

@media  (max-width:950px) {
  
	.homepage .splash {
		img { margin-right: 50px; }
		.text {
			p { font-size: 17px; }
		}
	}

	.homepage h3.quote { font-size: 24px; padding: 45px 0 60px 0; width: 100%; }

}

@media  (max-width:800px) {
  
	.homepage .splash { flex-direction: column;
		.text {
			p { font-size: 17px; }
		}
	}
}
