.new-article { 
	.splash { text-align: center; margin: 30px auto 0 auto; max-width: 1000px;

		.title {
			p.affiliate-disclosure { padding: 0px; margin: 0 0 10px; display: inline-block; border-radius: 3px; font-size: 15px; font-weight: 400;
				a { border-bottom: 1px dashed $primary-color; color: $primary-color; }
			}
			h1 { margin: 5px 0; font-size: 61px; text-wrap: balance; padding-bottom: 0px; font-family: "Young Serif", serif; 
				span { text-decoration: underline;
					text-decoration-color: $blue;
					text-decoration-thickness: 8px; /* Adjust thickness */
					text-underline-offset: 4px }
			}
			h3 { margin: 20px 100px 10px 100px; text-wrap: balance; font-size: 22px; font-weight: 400;
				a { color: $primary-color; border-bottom: 1px solid $light-blue;}
			}
			.published { position: relative; height: 100px; display: flex; gap: 10px; justify-content: center; align-content: center;
				p { font-size: 14px; line-height: 140%; font-weight: 400;  align-self: center;
					span { display: block; font-size: 12px; }
					a { color: $primary-color; border: none; }
				}
				#date { text-align: right; margin: 0; }
				#author { text-align: left; margin: 0px;  }
				img { border-radius: 100%; height: 85px; width: 83px; margin: 5px 10px; border: 2px solid #fff; }
			}
		}

		.overview {
			.logos { display: grid; grid-template-columns: 1fr 1fr 1fr 1fr; gap: 15px; border: 2px solid; border-radius: 20px; padding: 30px 20px; margin: 60px 0; position: relative;
				h3 { position: absolute; width: 200px; font-family: "Young Serif", serif;  font-size: 21px; color: #fff; padding: 4px 0; border-radius: 10px; top: -44px; left: 50%; margin-left: -100px; }
				.logo { border: 2px solid #E6EADB; background: #fff; border-radius: 20px; height: 65px; display: flex; position: relative; align-items: center; justify-content: center; transition: all 0.3s; -webkit-transition: all 0.3s; -moz-transition: all 0.3s;
					img { }
					span { padding: 2px 0; position: absolute; background: #CBD1BB; border-radius: 10px; top: 0; left: 50%; width: 120px; margin-left: -60px; top: -10px; color: #000; font-size: 12px; transition: all 0.3s; -webkit-transition: all 0.3s; -moz-transition: all 0.3s;
						svg { transition: fill 0.3s; -webkit-transition: fill 0.3s; -moz-transition: fill 0.3s; }
					}
				}
				.logo:hover { border: 2px solid $blue; 
					span { background: $blue; color: #fff; 
						svg { fill: #fff; }
					}
				}
			}
			.the-best { border-color: $blue; 
				h3 { background: $blue;  }
			}
			.the-rest { border-color: #000; 
				h3 { background: #000; }
			}
		}
	}

	img.cover-image { max-width: 100%; margin: 20px auto 40px auto; }

	.new-article-article { display: flex; gap: 40px; max-width: 1200px; margin: 0 auto;

		.new-article-sidebar { width: 340px; position: sticky; top: 20px; height: 100vh;
			h3 { display: none; }
			h4 { text-transform: uppercase; font-size: 12px; margin: 20px 0 0 0; color: $blue; }
			ul { list-style: none; margin: 0; padding: 20px; border: 2px solid #000; border-radius: 20px; 
				a { color: #000; line-height: 200%; }
				hr { margin: 12px 0; opacity: 0.2; background: #000; height: 1px; }
			}
		}
		.new-article-main { flex: 1; 
			h2 { font-family: "Young Serif", serif; font-size: 30px; margin-top: 40px; }
			h3 { font-family: "Young Serif", serif; font-size: 21px;}
			h2:first-child { margin-top: 0; }
			a { text-decoration: underline;}
			.image { max-width: 100%; text-align: center;
				img { max-width: 100%; }
				p { margin: 3px 0 0 0; font-size: 13px; }
			}
			p a.cta { background: none; display: inline; padding: 0; border-radius: 0; font-size: 17px; color: $blue; font-weight: 300; }
			h3 a.cta { background: none; display: inline; padding: 0; border-radius: 0; font-size: 21px; color: $blue; font-weight: 300; }
			ul.grid { gap: 2px 22px; margin: 30px auto; display: grid;grid-template-columns: repeat(3, 1fr);  }
			p.summary { border: 2px solid $blue; padding: 24px; background: #fff; border-radius: 10px; }
			.logos { display: grid; grid-template-columns: 1fr 1fr 1fr 1fr 1fr; gap: 5px; margin: 30px 0; position: relative;
				h3 { position: absolute; width: 200px; font-family: "Young Serif", serif;  font-size: 21px; color: #fff; padding: 4px 0; border-radius: 10px; top: -44px; left: 50%; margin-left: -100px; }
				.logo { border: 2px solid #E6EADB; background: #fff; border-radius: 20px; height: 65px; display: flex; position: relative; align-items: center; justify-content: center; transition: all 0.3s; -webkit-transition: all 0.3s; -moz-transition: all 0.3s;
					img { }
					span { padding: 2px 0; position: absolute; background: #CBD1BB; border-radius: 10px; top: 0; left: 50%; width: 120px; margin-left: -60px; top: -10px; color: #000; font-size: 12px; transition: all 0.3s; -webkit-transition: all 0.3s; -moz-transition: all 0.3s;
						svg { transition: fill 0.3s; -webkit-transition: fill 0.3s; -moz-transition: fill 0.3s; }
					}
				}
				.delete { opacity: 0.2; }
				.logo:hover { border: 2px solid $blue; 
					span { background: $blue; color: #fff; 
						svg { fill: #fff; }
					}
				}
			}
		}

	}
}

@media screen and (max-width:850px) {

	.new-article { 
		.splash { margin-top: 0px;
	
			.title {
				p.affiliate-disclosure { font-size: 13px; }
				h1 { font-size: 38px; }
				h3 { margin: 20px 20px 10px 20px; font-size: 17px; }
			}

			.overview {
				.logos { grid-template-columns: 1fr 1fr; padding: 40px 20px 30px 20px; 
					h3 { font-size: 19px; }
					.logo span { font-size: 11px; }
				}
			}
		}
	
	
		.new-article-sidebar {  display: none; }
		.new-article-main { 
			.logos { grid-template-columns: 1fr 1fr 1fr; }
			h2 { font-size: 25px; }
			h3 { display: block; font-family: "Young Serif", serif; font-size: 21px; margin-top: 0; }
		}

		
		
	}

}