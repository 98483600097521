.data-and-trends {

	.title { padding: 200px 0; margin: 0px auto; background-image: radial-gradient(circle closest-side, #fffde7, #ffe1ce); 
		h1 { font-size: 60px; margin: 0; text-transform: uppercase; font-weight: 700; }
		p.intro { margin: 15px auto 25px auto; font-size: 24px; max-width: 800px; }
		ul { list-style: none; margin: 25px auto 25px auto; padding: 0; 
			li { display: inline; font-size: 20px; color: $blue; margin: 0 10px; padding-bottom: 2px; border-bottom: 2px solid $light-blue; }
		}
	}
	
	.graph { margin-bottom: 100px;
		
		.text { text-align: center;
			h3 { background: $light-blue; color: $blue; padding: 10px 12px; display: inline-block; text-transform: uppercase; font-size: 12px; border-radius: 8px; }
			h2 { margin: 10px 0; font-size: 42px;  }
			ul,p { margin: 20px auto; font-size: 18px; max-width: 800px; }
			ul { list-style: square; }
			p.small { color: $secondary-color; font-size: 13px; }
		}
		
	}
	
}