.more-margin { margin-top: 40px; }

.article { text-align: left; max-width: 840px; padding-top: 3vh; margin: auto;

	.title { text-align: center; margin: 0px auto; 

		ul.breadcrumbs { list-style: none; margin: 0 0 6px 0; padding: 0;
			li { margin-right: 10px; padding-right: 20px; display: inline-block; background: url('/assets/images/icons/breadcrumb.png') no-repeat; background-size: 12px 12px; background-position: 100% 50%; 
				a { color: $primary-color; font-size: 14px; }
			}
			li:last-child { background-image: none; }
		}
		
		p.affiliate-disclosure { background: #fff; border: 1px solid $border; padding: 10px;  display: inline-block; border-radius: 3px; font-size: 14px; font-weight: 400; display: none;
			a { border-bottom: 1px dashed $primary-color; color: $primary-color; }
		}
		p.affiliate-disclosure-companies { display: inline-block; }
		img.stars { max-width: 170px; margin-bottom: 10px; }
		h1 { margin: 0 0 5px 0; text-wrap: balance; } 
		h3 { margin: 15px 0px 0 0; font-weight: 500; line-height: 140%; font-size: 25px; text-wrap: balance;
			a { color: $primary-color; border-bottom: 1px solid $light-blue;}
		}
		p.published { font-size: 14px; color: $primary-color; line-height: 200%; font-weight: 300; padding-bottom: 15px; margin: 20px 0 40px 0;
			span { margin: 0 5px; }
			a { color: $primary-color; border-bottom: 1px solid #CCC; padding-bottom: 2px; }
		}
		
		
		.title-image { width: 100%; margin: 20px 0 0 0; border-radius: 3px; } 
		iframe { height: 386.5px; width: 100%; border: none; }

	}
}
			
.content { 
			
	h3 { margin-top: 50px; line-height: 130%; font-size: 20px; font-weight: 600; }
	h4 { margin-top: 50px; font-size: 20px; font-weight: 600; line-height: 130%; }

	p,ul,ol { 
		a { color: $blue; font-weight: 400; padding-bottom: 2px; border-bottom: 1px solid $blue;}
		a:hover { color: $blue; }
		li { margin-bottom: 10px; }
	}
	
	p a.cta { background: none; display: inline; padding: 0; border-radius: 0; font-size: 17px; color: $blue; font-weight: 400; padding-bottom: 2px; border-bottom: 1px solid $blue;}

	
	hr { margin: 40px auto; }

	table { border-collapse: collapse; width: 100%; max-width: 760px; margin: 0 0 20px 0; border-radius: 2px; overflow-x: auto;
		th,td { vertical-align: top; padding: 1.5% 3.5% 1.5% 1.5%; line-height: 165%; } 
		th { border-bottom: 2px solid $border; border-top: 2px solid $border; font-weight: 500; text-align: left; }
		td { font-size: 16px; }
		tr { border-bottom: 1px solid $border; }
//				tr:nth-child(odd) { background-color: #FAFAFA; }
		tr:hover { background: #FAFAFA; }
	}

	.bubble { background: $lightest-blue; border-radius: 4px; padding: 30px; margin: 20px 0; line-height: 165%; font-weight: 300;
		h3 { margin-top: 0; }
	}

	code { word-wrap: break-word; background: $lightest-blue; padding: 10px; }
	
	a.toggle { float: right; display: inline-block; border-radius: 15px; border: 5px solid #eee; height: 32px; width: 42px; margin-left: 20px; background: url('/assets/images/icons/top.png') no-repeat; background-size: 16px 16px; background-position: 50%; text-indent: -9999em; display: none; }
	

	blockquote { max-width: 738px; margin: 20px auto; }
	img { margin: 20px auto 0 auto; width: 100%; max-width: 900px; box-shadow: 0 0 0 1px #ddd; -webkit-box-shadow: 0 0 0 1px #ddd; -moz-box-shadow: 0 0 0 1px #ddd; }
	iframe.vimeo { margin-top: 20px; width: 900px; height: 505px; }
	.wistia {  width: 100%; max-width: 900px; margin: 0 auto;
		.wistia_responsive_padding { margin: 20px auto 0 auto; box-shadow: 0 0 0 1px #ddd; -webkit-box-shadow: 0 0 0 1px #ddd; -moz-box-shadow: 0 0 0 1px #ddd; }
	}
	a.wistia-thumbnail { padding-bottom: 0; border-bottom: 0; display: block; max-width: 900px; text-align:center; margin: 30px auto 10px auto; position: relative; transition: opacity 0.2s; -webkit-transition: opacity 0.2s; -moz-transition: opacity 0.2s;
		img { z-index: 0; display: block; box-shadow: 0 0 0 1px #eee; -webkit-box-shadow: 0 0 0 1px #eee; -moz-box-shadow: 0 0 0 1px #eee; border-radius: 3px; margin: 0; }
		img.play { z-index: 100; position: absolute; bottom: 50%; left: 50%; margin-left: -30px; margin-bottom: -30px; width: 60px; display: block; height: 60px; box-shadow: 0 0; -webkit-box-shadow: 0 0; -moz-box-shadow: 0 0;  }		
		.video-byline { border-radius: 3px; width: 72%; padding: 10% 15% 40px 13%; position: absolute; text-align: left; font-weight: 300; line-height: 135%;  }
	}
	a.wistia-thumbnail:hover { opacity: 0.5; }
	
	a.button { display: inline-block; background: $blue; padding: 10px 20px; border-bottom: 0; border-radius: 5px; color: #fff; margin: 20px 0;  }

	p.caption { font-size: 14px; font-weight: 500; color: $secondary-color; margin: 3px auto 40px auto; max-width: 900px;  }

	#introduction { margin-bottom: 40px;  }

	.at-a-glance { border: 1px solid $blue; border-radius: 10px; margin: 40px auto;
		h4 { padding: 20px 30px; margin: 0; border-bottom: 1px solid $blue; font-size: 19px;  }
		ul { list-style: square; padding: 0; margin: 30px 50px; 
			li { font-size: 24px;
				h4 { border-bottom: none; padding: 0; }
				a { color: $primary-color; font-weight: 400; border-bottom: none; }
			}
		}
	}
	
	.inline-table-of-contents { 
		h4 { padding: 20px 30px; margin: 0; border-bottom: 1px solid $blue; font-size: 19px;  }
		ul { list-style: square; padding-left: 56px; }
	}

	.chapter { padding: 20px 0; margin-top: 20px; 
		h2 { margin-top: 0; }
		.inner-section { position: relative; }
	 }
	.chapter-with-padding { padding-top: 80px; }

	ul.related-articles { display: grid; grid-template-columns: 1fr 1fr; grid-gap: 30px; list-style: none; margin: 0; padding: 0; 
		li { 
			a { display: block; border-bottom: 0;
				h4 { margin: 5px 0 0 0; color: $primary-color; }
				p { font-size: 15px; line-height: 180%; margin: 10px 0 0 0 ; color: $primary-color; }
				img { width: 100%; }
			}
			a:hover { 
				h4,p { color: $blue; }
			}
		}
	}
								
	table.roundup { border-top: 3px solid $light-blue; border-collapse: collapse; margin: 20px 0 60px 0; width:100%; max-width: 100%; display: table;
		tr {
			td { border-bottom: 1px solid $lightest-blue; padding: 25px 0; margin: 0; vertical-align: top; min-width: 0px; }
			td.name { width: 21%; padding-right: 2%; text-align: left; 
				h4 { margin: 0; font-size: 17px; font-weight: 600;  
					a { color: $primary-color; padding-bottom: 1px; border-bottom: 3px solid $lightest-blue; }
				}
			}
			td.desc { width: 39%; padding-right: 2%; text-align: left; 
				p { font-size: 15px; font-weight: 300; margin: 0; line-height: 135%; }
				ul { margin-top: 0; padding-left: 18px; line-height: 150%; font-size: 15px; 
					li { margin-bottom: 10px; }
				}
			}
			td.stars { width: 15%; text-align: left; 
				.stars { margin: 0; max-width: 80px; box-shadow: none !important; -webkit-box-shadow: none !important; -moz-box-shadow: none !important; }
			}
			td.cta { width: 23%; text-align: right; padding: 18px 0;
				.cta { font-size: 13px; padding: 5px 0px; width: 100%; }
			}
		}
		tr:nth-child(odd) { background-color: #fff; }
		tr:hover { background: $light-border; 
			td.name h4 a { border-bottom: 3px solid $light-blue; }
		}
	}
	
	.roundup-more-info { padding: 0px 0 0 0; list-style: none; margin: 0 auto; max-width: 900px;
		li { margin: 0;  
			.sticky-bar { padding: 20px 0 0 0; background: #fff; position: relative; height: 50px; z-index: 1000; box-shadow: 0px 0 0px 3px #fff; -webkit-box-shadow: 0px 0 0px 3px #fff; -moz-box-shadow: 0px 0 0px 3px #fff;
				h2 { margin: 0px auto; padding-bottom: 10px; position: absolute; top: 17px; left: 0px; }
				img.stars { position: absolute; top: 4px; right: 230px; max-width: 120px; box-shadow: none; -moz-box-shadow: none; -webkit-box-shadow: none; }
				.cta { position: absolute; top: 16px; right: 0px; padding: 7px 15px; font-weight: 500; width: 170px; color: #fff; border-bottom: 0; }
				.toggle-roundup { position: absolute; top: 10px; right: 0; width: 24px; height: 24px; padding: 10px; border-radius: 5px; text-indent: -99999em; border: 1px solid #999; background: #fff url('/assets/images/icons/top.png') no-repeat 50% 50%; background-size: 16px 16px; }
				.active { transform: rotate(90deg);}
			}
			hr { }
			.inner { padding: 0px 0 30px 0; }
			p:first-of-type { margin-top: 10px; }
			ul { list-style: square; margin-left: 10px; padding-left: 18px;
				li { margin: 10px 0 20px 0; }
			}
		}
	}
	

}
	
	
	

@media screen and (max-width: 1000px) {

	.article-no-sidebar { width: 90%; margin: 0 5%; }
	.article { width: 100% !important; margin: 0 !important;
		
		.main { width: 90%; margin: 0 5%; 
			
			.content { 
				
				.roundup-more-info { 
					li { 
						.sticky-bar { height: 85px; 
							h2 { top: 70px; font-size: 18px; }
							.cta { top: 46px; padding-left: 5px; padding-right: 5px; font-size: 13px; }
							.toggle-roundup { top: 50px; width: 10px; padding: 10px 5px; border: none; background-color: transparent; }
							img.stars { top: 30px; left: 0; width: 80px; right: auto; }
						}
						.inner { padding: 10px 0 30px 0; }
					}
				}
				
				table { overflow-x:auto; }
				
				table.roundup { 
					tr {
						td.name { width: 33%; padding-right: 4%; 
							h4 { font-size: 15px; }
						}
						td.desc { width: 40%; padding-right: 7%; }
						td.stars { width: 20%; 
							.stars { width: 100%; }
						}
						td.cta { display: none; }
					}
					tr:hover { background: $light-border; 
						td.name h4 a { border-bottom: 3px solid $light-blue; }
					}
				}	
				
						
			}
		}
	}
	
}

@media screen and (max-width: 840px) {

	.article { 
		
			.title { width: 90%; margin: 0 5%; 
				p.affiliate-disclosure { font-size: 12px; }
				img.stars { max-width: 130px; }
				h1 { font-size: 38px;  }
				h3 { font-size: 21px; margin: 20px 0 10px 0; }
				.published { margin: 10px 0 20px 0; padding-bottom: 0;
					p {
						span { float: none; display: inline; }
						img { display: none !important; }
						a { float: right; }
					}
				}
				iframe { height: 250px;  }
				.title-image { margin: 10px 0 0 0; } 
			}

			
			.content { width: 90%; margin: 5%; 
				
				.inline-table-of-contents ul { padding-left: 16px; }
				
				.at-a-glance ul { margin: 30px 20px 30px 50px; }
		
				a.wistia-thumbnail { 
					.video-byline { padding-bottom: 21px;
						img.play { width: 24px; height: 24px; bottom: 20px; }
					}
				}
				
	
				.image-right { display: block; float: none; margin: 0; }
		
				table { display: block; 
					td { min-width:100px; }
				}
				iframe.vimeo { width: 100%; height: auto; }
				.author { padding: 5% 5% 5% 35%; background: url('/assets/images/author-photo-mobile.jpg'); background-size: 100px 100px; background-position: 15px 15px; background-repeat: no-repeat; 
					h4 { font-weight: 700; margin: 10px 0; }
					p { margin: 10px 0;}
				}
				.author-no-image { background: none; padding: 5%; }
			
				.book-ad { 
					div h3 { font-size: 21px;}
					img { width: 100px; margin: 0; }
				}
			
		}
	}
  
}
