.footer { margin-top: 0px; clear: both;

	h3 { font-size: 26px; font-weight: 400; margin: 40px 0; max-width: 800px; line-height: 150%; }
	p { margin: 20px 0 30px 0; font-weight: 400; font-size: 15px;
		a { display: inline-block; float: right; color: $primary-color; border-bottom: 1px solid #000; padding-bottom: 1px; margin-left: 20px;  }
	}

	ul { width: 100%; list-style: none; margin: 0; padding: 0; text-align: left;
		li { width: 23.5%; margin-right: 1.5%; display: inline-block; float: left;
			h4 { text-transform: uppercase; font-size: 15px; font-weight: 600; margin-top: 50px; }
			ul { display: block; margin-bottom: 70px;
				li { display: block; float: none; margin-bottom: 20px; width: 100%; 
					a { color: $primary-color; font-size: 17px; font-weight: 300; display: block; }
					a:hover { color: $blue; }
				}
			}
		}
	}
}

@media screen and (max-width: 800px) { 

	.footer { 

		h3 { font-size: 20px;  }

		p { text-align: center; margin-top: 50px; 
			a { display: block; text-align: center; margin-top: 8px; float: none; border-bottom: none; }
		}

		ul li { width: 100%; margin-right: 0; 
			h4 { margin-top: 50px; }
			ul { margin-bottom: 0; }
		}

	}	


}