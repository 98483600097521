.dropdown-lightbox { display: none; position: fixed; z-index: 999; top: 0px; left: 0; right: 0; bottom: 0; background: rgba(0, 0, 0, 0.6); }

.header { padding: 20px 0 0 0;  position: relative; margin-bottom: 2px; z-index: 150000; background: #fff;

	.wide-wrapper { display: flex; align-items: center; justify-content: space-between;
		
		a#logo { display: inline-block; width: 190px; margin-top: 2px;
			img { border: none; width: 100%; }
		}
		
		nav { display: block; text-align: right;
			ul { list-style: none; margin: 0; padding: 0; 
				li { display: inline-block; font-weight: 400; font-size: 15px; margin-left: 20px;
					a { color: $primary-color; display: block; }
					a.dropdown { display: flex; align-items: center; gap: 4px;
						svg { width: 16px; height: 16px; }
					}
					a.dropdown-active { 
						svg { transform: rotate(180deg); }
					 }
					.dropdown-container { background:#fff; width: 100%; padding: 30px 0 60px 0; position: absolute; top: 49px; left: 0; margin: 0 auto; display: none; z-index: 120000; text-align: left; 
						.dropdown-inner { width: 90%; margin: 0 5%; max-width: 1600px; display: grid; grid-template-columns: 1fr 1fr 1fr 1fr; margin: 0 auto;
							ul {  
								h4 { margin: 0; 
									a { text-transform: uppercase; font-size: 14px; font-weight: 600; margin: 0;  }
								}
								li { margin-left: 0; display: block;
									a { display: block; font-size: 15px; font-weight: 400; padding: 3px 0px; line-height: 160%; 
										span { font-size: 14px; color: $secondary-color; }
									}
									a.blue { color: $blue; }
									a:hover { color: $blue;  
										span { color: $blue;  }
									}
								}
							}
						}
					}
				}
				li.small-dropdown {
					ul { width: 140px; margin-left: -65px; }
				}
			}
		}
	
		#toggle-mobile-menu { display: none; }
	
	}
	
}

body.data-and-trends .header { -webkit-box-shadow: none; box-shadow: none; -moz-box-shadow: none; border: none; }
body.border .header { -webkit-box-shadow: none; box-shadow: none; -moz-box-shadow: none; border: none; }
body.green .header { background: #E6EADB; -webkit-box-shadow: none; box-shadow: none; -moz-box-shadow: none; border: none;  }


@media screen and (max-width:1100px) { 

	.dropdown-lightbox { display: none !important; }

	.header { padding: 20px 0; margin-bottom: 0;
		.wide-wrapper { position: relative; display: block;

			a#logo { margin-top: 8px; width: 175px; }
			nav { display: block; display: none; position: relative; top: auto; right: auto; padding: 5% 0 2.5%;
				ul { 
					li { display: block; margin-left: 0; font-size: 16px;
						a { display: block; margin: 0; padding: 1.5% 0%; text-align: left; } 
						.dropdown-container { position: relative; top: auto; left: auto; width: 100%; padding: 2.5% 0%; border: none; 
							.dropdown-inner { display: block; background: $lightest-blue; padding: 1% 3%; 
								ul { margin-bottom: 10px; }
							}
						}
						ul:after { border: none; }
					}
					li.small-dropdown {
						ul { width: 90%; margin-left: 0px; }
					}
				}
			}
			#toggle-mobile-menu { position: absolute; top: -4px; right: 0; width: 24px; height: 24px; padding: 10px 0 10px 10px; display: inline-block; background: url('../images/icons/mobile-menu-2.png') no-repeat; background-size: 24px 24px; background-position: 50%; text-indent: -9999em;  }
	
		}
	}
	
}