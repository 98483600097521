
.ag-subheader { position: relative; display: flex; background: #fff; padding: 15px 0; min-height: 45px; width: 100%; justify-content: space-between; align-items: center;
	
	a.back { color: $primary-color; font-size: 15px; padding: 0px 0 0px 20px; background: url("../images/icons/back.png"); background-size: 12px 12px; background-repeat: no-repeat; background-position: 0 50%; }
	
	p.affiliate-disclosure { margin: 0; padding: 10px 15px; font-size: 13px; font-weight: 400;  background: #fff; border: 1px solid $border; border-radius: 3px; 
		a { text-decoration: none; border-bottom: 1px dashed #000; padding-bottom: 2px; color: #000; }
	}

}

.ag-wrapper { display: flex; gap: 30px; margin: 30px auto;

    .ag-sidebar { list-style:none; margin: 0px 0; padding: 20px 0 40px 0; width: 300px; position: sticky; top: 0px; align-self: flex-start;  height: 90vh; overflow-y: scroll;
        h4 { font-size: 15px; margin: 30px 0 10px 0; }
        h4:first-child { margin-top: 0; }
        a.mobile-toggle { display: none; }
        li { margin-bottom: 5px;
            a { font-size: 15px; display: flex; gap: 10px; color: #000;
                img,svg { width: 18px; }
            }
        }
    }

    .ag-templates { width: 100%; list-style: none; margin: 0; padding: 0; display: grid; grid-template-columns: 1fr 1fr; gap: 30px;
        li { 
            a.templates-cta { display: block; position: relative; 
                img { width: 100%; border: 1px solid #ddd; }
                .info { display: flex; align-items: flex-start; justify-content: space-between; opacity: 0; width: 90%; position: absolute; border: 1px solid #ddd; bottom: 5%; left: 2.5%; background: rgba(255, 255, 255, 0.8); padding: 2.5%; border-radius: 6px; backdrop-filter: blur(10px); -webkit-backdrop-filter: blur(10px); transition: opacity 0.5s; -moz-transition: opacity 0.5s; -webkit-transition: opacity 0.5s;
                    .meta { align-self: center;
                        h3 { line-height: 120%; margin: 0 0 2px 0; font-size: 19px; color: #000; font-weight: 900;  }                
                        p { margin: 0; font-size: 13px; color: #000; }    
                    }
                    span.button { font-size: 14px; font-weight: 500; display: inline-block; background: $blue; padding: 15px 25px; border-radius: 3px; color: #fff; }
                }
            }
            a.templates-cta:hover {  
                .info { opacity: 1;}
            }
        }
    
    } 

}

@media screen and (max-width: 960px) {

    .ag-subheader { justify-content: center; 
        a.back { display: none; }
    }

    .ag-wrapper {
        .ag-templates { grid-template-columns: 1fr; gap: 40px; 
            li { 
                a.templates-cta { 
                    .info { opacity: 1; position: relative; width: 100%; bottom: auto; left: auto; border: none; padding: 0; border-radius: 0px; margin: 5px 0 25px 0; 
                        span.button { font-size: 13px; min-width: 95px; text-align: center; }
                    }
                }
            }
        }
    }
    
    
	
}


@media screen and (max-width: 720px) {

    .ag-wrapper { display: block; 
        .ag-sidebar { width: 100%; z-index: 9999999; top: 0; position: relative; background: #fff;  width: 95%; padding: 2.5%; border: 1px solid $border; border-radius: 3px; 
            a.mobile-toggle { display: flex; gap: 4px; align-items: center; justify-content: center;
                svg { width: 18px; color: #000; }
                span { font-size: 15px; color: #000; }
            }
            .mobile-content { display: none; margin-top: 20px; }
        }
          
    }

}
