.inspiration { 
	.title { text-align: center; margin: 5vh auto 0 auto; max-width: 1000px;

		h1 { margin: 5px 0; text-wrap: balance; font-weight: 900; }
		h3 { margin: 20px 100px; text-wrap: balance;
			a { color: $primary-color; border-bottom: 1px solid $light-blue;}
		}
		.filter { position: relative; display: inline;
			input { border: 1px solid #000; width: 300px; border-radius: 100px; padding: 10px 40px 10px 20px; font-size: 17px; outline: none; }
			svg { position: absolute; top: 0; right: 20px; }
		}
		#errorMessage { margin: 100px 0; font-size: 21px; font-style: italic; display: none; }
		.published { position: relative; height: 80px; display: flex; justify-content: center; align-content: center;
			p { font-size: 15px; line-height: 140%; font-weight: 400;  align-self: center;
				span { display: block; font-size: 13px; }
				a { color: $primary-color; border: none; }
			}
			#date { text-align: right; margin: 10px 0px 0 0; }
			#author { text-align: left; margin: 10px 0 0 0px;  }
			img { border-radius: 100%; max-width: 70px; margin: 5px 10px; -webkit-box-shadow: 0 0px 0px 1px #ccc; box-shadow: 0 0px 0px 1px #ccc; -moz-box-shadow: 0 0px 0px 1px #ccc; }
		}

	}
	
	#introduction { text-align: left; max-width: 760px; margin: 0 auto;
		a { color: $blue; border-bottom: 1px solid $blue;}
		//	p:first-child { font-size: 21px; line-height: 160%;  }
		img { max-width: 100%; }
		li { margin-bottom: 5px; }
		p.affiliate-disclaimer a { color: $primary-color; border-bottom: 1px solid #151515; padding-bottom: 3px; }
		p a.cta { background: none; display: inline; padding: 0; border-radius: 0; font-size: 17px; color: $blue; font-weight: 300; border-bottom: 1px solid $blue;}
		p.arrow-tip { border-radius: 10px; background: #f7f7f7; display: inline-block; padding: 9px 18px; font-size: 15px; font-weight: 500;
			span { background: #3D4CF4; background: linear-gradient(to right, #3D4CF4 0%, #FF2862 100%); -webkit-background-clip: text; -webkit-text-fill-color: transparent; }
		}
	}
	

}

ul.inspiration-index { list-style: none; margin: 5vh auto 5vh; padding: 0; display: grid; grid-template-columns: 1fr 1fr 1fr 1fr; align-items: flex-start; justify-items: flex-end; grid-gap: 20px; column-gap: 40px; max-width: 1600px; width: 90%; text-align: left;
	li { 
		h1 { margin-top: 0; font-size: 43px;  }
		h3 { font-size: 21px;  }
		a { display: block; color: $primary-color; width: 100%; 
			img { width: 100%; border-radius: 3px; -webkit-box-shadow: 0 0 6px #eee; -moz-box-shadow: 0 0 6px #eee; box-shadow: 0 0 0 6px #eee; transition: all 0.2s; -moz-transition: all 0.2s; -webkit-transition: all 0.2s;  }
			h4 { border-bottom: 2px solid #fff; display: inline-block; font-weight: 500; margin: 12px 0 20px 0; transition: all 0.2s; -moz-transition: all 0.2s; -webkit-transition: all 0.2s; }
			.video-thumb { position: relative; background: #000; border-radius: 10px;
				img#image { display: block; transition: all 0.2s; -moz-transition: all 0.2s; -webkit-transition: all 0.2s; }
				#icon { background: $blue url('../images/play-circle.png') no-repeat; background-position: 50% 50%; background-size: 32px 32px; position: absolute; top: 50%; left: 50%; width: 100px; height: 50px; margin-left: -50px; margin-top: -25px; text-indent: -99999em; display: block; -moz-box-shadow: none; -webkit-box-shadow: none; box-shadow: none; }
			}
		}
		a:hover {
			h4 { color: $blue; }
			img { -webkit-box-shadow: 0 0 6px $blue; -moz-box-shadow: 0 0 6px $blue; box-shadow: 0 0 0 6px $blue; }
		}
	}
}

.inspiration-footer { display: grid; grid-template-columns: 1fr 1fr; align-items: center; justify-items: flex-start; border-top: 1px solid $border; padding-top: 30px;
	a { display: inline-block; padding: 5px 10px; border-radius: 10px; color: #2962FF; font-weight: 500; font-size: 15px; border: 3px solid #2962FF; justify-self: flex-end; margin-top: 2px; }
}

h2.more-examples { text-align:center; margin: 60px 0 20px 0;}

.example-website { position: relative; padding: 40px 0 0 0; text-align: center;
	.sticky-bar { backdrop-filter: blur(12px); -moz-backdrop-filter: blur(12px); -webkit-backdrop-filter: blur(12px); background: rgba(255,255,255,0.85); width: 100%; padding: 3px 0; z-index: 1000;
		.inside { position: relative; max-width: 760px; margin: 0 auto; text-align: left;
			h2 { text-align: left; font-size: 21px; font-weight: 500; position: relative; margin: 6px 0 6px 0;
				a { color: $primary-color; }
				span.template-span { font-size: 14px; font-weight: 400; display: inline-block; margin: 0;  }
			}

			// Block
			span.made-with { display: block; text-align: left; margin: 6px 0 12px 0; 
				a.cta { text-transform: none; font-size: 19px; font-weight: 500; background: none; padding: 0 0 1px; display: inline; margin: 0; width: auto; height: auto; color: $blue; border-bottom: 2px solid $blue; border-radius: 0; padding-bottom: 2px; -webkit-box-shadow: none; box-shadow: none; -moz-box-shadow: none; }
			}

			p.coupon { text-align: left; background: #FCFFA4; font-size: 14px; margin: 0 0 20px 0; font-weight: 500; padding: 1px 3px; display: inline-block; }
			// Right aligned
//			span.made-with { font-size: 21px; position: absolute; top: 0; right: 0;
//				a.cta { text-transform: none; font-size: 21px; font-weight: 700; background: none; padding: 0 0 4px; display: inline; margin: 0; width: auto; height: auto; color: $blue; border-bottom: 2px solid $blue; border-radius: 0; padding-bottom: 2px; -webkit-box-shadow: none; box-shadow: none; -moz-box-shadow: none; }
//			}
		}
	}
	.description { text-align: center;
		img { margin: 20px auto 0 auto; width: 100%; max-width: 900px; box-shadow: 0 0 0 4px #eee; -webkit-box-shadow: 0 0 0 4px #eee; -moz-box-shadow: 0 0 0 4px #eee; }
		p,ul,ol { margin: 20px auto; max-width: 760px; text-align: left; 
			a.example-link { color: $primary-color; text-decoration: underline; border: none; font-size: 17px; }
		}		
	}
	img { border-radius: 3px; -webkit-box-shadow: 0 0 4px #eee; -moz-box-shadow: 0 0 4px #eee; box-shadow: 0 0 0px 4px #eee; }
	.screenshot { text-align: center;  margin: 0 auto 20px auto; max-width: 1050px;
		img { margin: 5px 0 10px 0; max-width: 1050px;  }
	}
}


.template-suggestion { text-align: center; margin: 30px auto 30px auto; max-width: 1350px;  padding: 0 24px;
	a.cta { background: none; display: inline; padding: 0; border-radius: 0; font-size: 23px; color: $blue; font-weight: 500; }
	a.paid-cta { display: none; }
	h3 { max-width: 760px; font-weight: 500; text-align: left; margin: 0 auto; 
		a { color: $blue; }
	}
	.images { display: flex; gap: 24px; width: 100%; margin: 12px auto 20px auto;
		a img { border: 4px solid #eee; width: 100%; border-radius: 8px; }
		a:hover img { border: 4px solid $blue; }
	}
	p { max-width: 760px; text-align: left; margin: 0 auto; 
		a { text-decoration: underline; }
	}
}

.email-course { width: 80%; padding: 0 10% 30px 10%; margin: 100px auto 20px auto; text-align: center; background: #f7f7f7; border-radius: 30px; position: relative;
	span.free { position: absolute; top: 15px; right: 15px; border-radius: 20px; padding: 12px; background: $blue; color: #fff; font-weight: 500; text-transform: uppercase }
	h4 { color:#999; font-size: 16px; text-transform: uppercase; font-weight: 700; margin: 10px 0; }
	h2 { font-size: 33px; margin: 10px auto; max-width: 400px;
		span { color: #FF2862; }
	}
	hr { margin: 20px 0; }
	p { font-size: 18px;  }
	ul { text-align: left; padding-left: 19px;
		li { margin-bottom: 10px !important; font-size: 18px; 
			strong { font-weight: 500; color: #FF2862; }
		}
	}
	img { margin-top: -90px; max-width: 200px !important; }
	#mc_embed_signup { width: 100%; max-width: 608px; position: relative; margin-top: 40px;
		form { margin: 20px 0 0 0; }
		label { display: none; }
		input.email { font-size: 17px; padding: 5px 20px; max-width: 440px; border-radius: 50px 0 0 50px; height: 40px; background:#fff; border: none; border: 2px solid #FF2862;}
		input.button { -webkit-appearance: none; cursor: pointer; position: absolute; top: 0px; right: 0px; font-size: 17px; font-weight: 900; width: 130px; background: #FF2862; color: #fff; border-radius: 0 50px 50px 0; border: none; height: 54px; }
	} 
}

@media (max-width:1600px) {

	.example-website .screenshot { max-width: 900px;
		img { max-width: 900px; }
	}

	ul.inspiration-index { grid-template-columns: 1fr 1fr 1fr; }

}

@media screen and (max-width: 992px) { 

	.email-course { 
		span.free { font-size: 15px; padding: 10px; }
		h4 { font-size: 15px; }
		h2 { font-size: 33px; }
		p { font-size: 17px; }
		ul li { font-size: 17px; }
		img { max-width: 120px !important; margin-top: -55px; }
		#mc_embed_signup { 
			input.email { font-size: 15px; width: 90%; }
			input.button { font-size: 15px;  }
		}
	}

	.inspiration { 
		.title { margin-top: 10px;
			p.affiliate-disclosure { font-size: 12px; }
			h1 { font-size: 32px; }
			h3 { font-size: 21px; margin: 20px 0; }
			.filter input { width: 50%; min-width: 200px;  }
			.published {
				p { font-size: 13px;
					span { font-size: 11px; }
				}
			}
		}
		#introduction { 
			p:first-child { font-size: 17px; line-height: 165%;  }
			p.arrow-tip { display: none; }
		}
		
		.top-website-builders { grid-template-columns: 1fr; padding: 0; border: none;
			h2 { margin-bottom: 0; font-size: 21px; }
			ul { border-left: 0; padding-left: 18px; }
		}
		
	} 

	.example-website { padding: 10px 0 0 0;
		.sticky-bar {
			.inside {  width: 90%; padding: 0px 5%;
				h2 { font-size: 21px;  text-align:center;
					span.template-span { display: block; }
				}
				span.made-with { display: block; font-weight: 400; font-size: 15px; text-align:center;
					a.cta { font-weight: 400; font-size: 16px;  }
				}
			}
		}
		img { width: 90%; max-width: 90%; margin: 5%;  }

		.screenshots {
			img.one, img.two,img.three { width: 90%; max-width: 90%; margin: 5%; }
		}

	}
	
	ul.inspiration-index { margin: 50px 0;  grid-template-columns: 1fr; width: 100%;
		li.inspiration-title { grid-column: 1; align-self: center; text-align: center;  }
		li { width: 90%; margin: 0 5%; }
	}
	
}

@media screen and (max-width: 660px) { 

	.email-course { 
		#mc_embed_signup { 
			input.email { max-width: 280px; }
		}
	}
}

@media screen and (max-width: 500px) { 

	.email-course { 
		#mc_embed_signup { 
			input.email { max-width: 200px;  }
			input.button { max-width: 110px; padding: 0; }
		}
	}
}