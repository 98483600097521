.new-review {
	
		.section { margin-top: 40px; }
		h2 { font-weight: 600 !important; }
		h4 { font-weight: 600 !important; }
		.section p a, .section ul a { border-bottom: 1px solid $light-blue; padding-bottom: 3px; font-weight: 400; }
		
		a.toggle { float: right; display: inline-block; border-radius: 15px; border: 5px solid #eee; height: 32px; width: 42px; background: url('/assets/images/icons/top.png') no-repeat; background-size: 16px 16px; background-position: 50%; text-indent: -9999em; }
		
		.bubble { border-radius: 5px; padding: 5px 40px; background: #f7f7f7; margin-bottom: 30px;
			.media { float: right; margin: 0px 0 20px 30px; 
				img { max-width: 300px;  }
				p { font-size: 12px; text-align: center; margin: 4px 0 0 0; }
			}
		}
		.green-bubble { background: #F9FCF9;}
		.red-bubble { background: #FFFAFB;}
		
		.price-bubble { padding-left: 100px; position: relative;
			h4 { margin: 20px 0 10px; }
			p { margin: 10px 0 20px 0; }
			span { font-weight: 700; font-size: 21px; position: absolute; top: 25px; left: 20px; width: 60px; text-align: center; }
		}
		
	
	.faq { border: 1px solid $border;
		.item { border-bottom: 1px solid $border;  padding: 0px 30px;
			h4 { font-size: 17px; margin: 20px 0; padding-right: 50px; background: url('/assets/images/icons/top.png') no-repeat; background-size: 16px 16px; background-position: 100% 50%; cursor: pointer; }
			p { margin: 0 0 20px 0; display: none; }
		}
	}

	.carousel { margin: 30px 0 50px 0; background: #EEE; border: 1px solid $border; border-radius: 3px; }

	.carousel img { display: block; height: 425px; width: 715px; }

}

// Media Queries
@media screen and (max-width:900px) { 
  
  .carousel img { height: 212.5px !important; width: 357.5px !important; }

  .new-review .main { 
	  
	  .bubble { padding: 5px 20px;
		  .media { float: none; margin: 20px 0;
			  img { width: 100%; }
		  }
	  }
	  
	  .price-bubble { padding-left: 20px; 
		  span { position: relative; display: block; top: auto; left: auto; width: 100%; text-align: left; margin: 20px 0;}
	  }
	  
  }

}	
