.roundup-subheader {position: relative; width: 100%; background: #fff; z-index: 1000;

	.inner { background: #fff; padding: 15px 0; min-height: 52px; max-width: 1600px; margin: 0 auto; width: 90%; position: relative; text-align: left; z-index: 122000; -webkit-box-shadow: 0 0 0 1px #fff; -moz-box-shadow: 0 0 0 1px #fff; box-shadow: 0 0 0 1px #fff; 
	
		ul.breadcrumbs { list-style: none; margin: 10px 0 6px 0; padding: 0; float: left;
			li { margin-right: 1px; padding-right: 18px; display: inline-block; background: url('/assets/images/icons/breadcrumb.png') no-repeat; background-size: 12px 12px; background-position: 100% 50%; 
				a { color: $primary-color; font-size: 15px; font-weight: 400; }
			}
			li:last-child { background-image: none; }
		}
	
		p.affiliate-disclosure { float: right; margin: 5px 0 0 10px; padding: 10px 15px; font-size: 13px; font-weight: 400; background: #fff; border: 1px solid $border; border-radius: 3px;  
			a { text-decoration: none; border-bottom: 1px dashed #000; padding-bottom: 2px; color: #000; }
		}
	}	

}

@media screen and (max-width:950px) { 

	.roundup-subheader { display: none; }

}
	
.roundup-title { margin: 40px 0; background: url('/assets/images/roundup-title.png') no-repeat; background-size: 1440px 156px; background-position: 50% 0%;
	.mobile-disclosure { display: none; }
	h1 { font-size: 52px; font-weight: 700; max-width: 700px; margin: 0 auto; text-wrap: balance; }
	h3 { font-size: 23px; font-weight: 400; line-height: 140%; max-width: 700px; margin: 10px auto 25px auto; text-wrap: balance; }
	.meta { display: flex; align-items: center; justify-content: center; gap: 10px;
		p { font-size: 14px; color: $primary-color; line-height: 160%; font-weight: 300; margin: 0; 
			a { color: $primary-color; text-decoration: none;}
		}
		.meta-author,.meta-date {  flex-grow: 1; flex-basis: 0; }
		.meta-author { text-align: right; }
		.meta-date { text-align: left; }
		.meta-profile-pic { width: 80px; height: 80px; border-radius: 80px; border: 1px solid #f7f7f7; -webkit-box-shadow: 0 3px 3px #eee; box-shadow: 0 3px 3px #eee; -moz-box-shadow: 0 3px 3px #eee; }
	}
}
	
	.roundup-content { margin: 0 auto; text-align: left; max-width: 840px;
		
		h2 { font-size: 36px; font-weight: 700; }
		h3 { font-size: 26px; font-weight: 700; margin: 35px auto 20px auto; line-height: 130%; }
		h4 { line-height: 130%; font-size: 20px; font-weight: 600;  }
		p,ul,ol { font-size: 18px; font-weight: 300; line-height: 160%; margin: 25px 0;
			strong { font-weight: 600; }
			a { padding-bottom: 2px; border-bottom: 1px solid $blue; text-decoration: none; }
		}
		p a.cta { background: none; display: inline; padding: 0 0 2px 0; border-radius: 0; font-size: 17px; color: $blue; font-weight: 300; padding-bottom: 2px; border-bottom: 1px solid $blue; text-decoration: none; }
		p.caption { font-size: 14px; font-weight: 300; color: $secondary-color; margin: 3px auto 40px auto; max-width: 900px;  }

		section.screenshots { display: grid; grid-template-columns: 1fr 1fr 1fr; column-gap: 10px; 
			a { display: inline-block; 
				img { border-radius: 3px; margin: 0; }
			}
		}
		.further { display: flex; background-size: 25%; margin-bottom: 2.5%; padding: 1%; background-color: $lightest-blue; border-radius: 4px; position: relative; 
			a {  width: 70%; border: none; padding-bottom: 0; margin: 1.5%; align-self: center; display: flex; flex-direction: column; font-weight: 600; font-size: 19px; color: $primary-color; text-align: left; }
			a:first-child { width: 30%; align-self: flex-start; }
			img { margin: 0; }
			span { font-weight: 400; font-size: 12px; display: block; }
		}
		
		#introduction p a { color: $primary-color; border-bottom: 1px solid $primary-color; }
		
		.aside { background: $lightest-blue; padding: 20px 40px; }
		
		.bubble { background: $lightest-blue; border-radius: 4px; padding: 30px; margin: 20px 0; line-height: 165%; font-weight: 300;
			h3 { margin-top: 0; }
		}
		
		a.button { display: inline-block; background: $blue; padding: 10px 20px; border-bottom: 0; border-radius: 5px; color: #fff; margin: 20px 0;  }
		
		
		a.back-to-toc { background: #F9FAFF; padding: 15px; margin-top: 30px; display: inline-block; border-radius: 3px; }

		img { margin: 20px auto 0 auto; width: 100%; max-width: 900px; outline: 1px solid #ddd; outline-offset: -1px; }
		a.wistia-thumbnail { padding-bottom: 0; border-bottom: 0; display: block; max-width: 900px; text-align:center; margin: 30px auto 10px auto; position: relative; transition: opacity 0.2s; -webkit-transition: opacity 0.2s; -moz-transition: opacity 0.2s;
			img { z-index: 0; display: block; box-shadow: 0 0 0 1px #eee; -webkit-box-shadow: 0 0 0 1px #eee; -moz-box-shadow: 0 0 0 1px #eee; border-radius: 3px; margin: 0; }
			img.play { outline: none; z-index: 100; position: absolute; bottom: 50%; left: 50%; margin-left: -30px; margin-bottom: -30px; width: 60px; display: block; height: 60px; box-shadow: 0 0; -webkit-box-shadow: 0 0; -moz-box-shadow: 0 0;  }		
			.video-byline { border-radius: 3px; width: 72%; padding: 10% 15% 40px 13%; position: absolute; text-align: left; font-weight: 300; line-height: 135%;  }
		}
		a.wistia-thumbnail:hover { opacity: 0.5; }
				
		.overview { list-style: none; margin: 30px 0 0 0; padding: 0; border-top: 1px solid #ddd;
			li { border-bottom: 1px solid #ddd; display: grid; grid-template-columns: 0.85fr 1.75fr .6fr 0.8fr; gap: 5px; padding: 15px 0; align-items: center;overflow: hidden;transition: background 0.2s; -webkit-transition: background 0.2s; -moz-transition: background 0.2s;
				h3 { margin: 0; font-size: 18px; font-weight: 600; 
					a { color: #000; border-bottom: none; padding-bottom: 0;}
				}
				p { font-size: 16px; font-weight: 300; margin: 0; }
				img { width: 98px; outline: none; margin: 0; }
				.cta { overflow: hidden; border-radius: 30px; padding: 5px 10px; font-size: 13px; }
			}
			li.star-3,li.star-2,li.star-1 { display: none; }
			li.overview-not-hidden { display: grid; }
			li:hover { background: #f7f7f7; 
				h3 a { text-decoration: underline; }
			}
			a#overview-show-more { display: block; padding: 10px 0; color: #000; border-bottom: 0; background: #f7f7f7; text-align: center; font-size: 15px; font-weight: 500; }
		}
		
		.bubble-numbers ul {   counter-reset: method-counter; 
			li {  margin: 0 0 1rem 0px; position: relative; counter-increment: method-counter; 
				strong { color: $blue; }
			}
			li::before {
			  content: counter(method-counter);
			  color: #fff;
			  font-size: 1.5rem;
			  font-weight: bold;
			  position: absolute;
			  --size: 32px;
			  left: calc(-1 * var(--size) - 10px);
			  line-height: var(--size);
			  width: var(--size);
			  height: var(--size);
			  top: 0;
			  transform: rotate(-10deg);
			  background: $blue;
			  border-radius: 50%;
			  text-align: center;
			  box-shadow: 1px 1px 0 #999;
			}
		}
		
		
		.mobile-table-of-contents { border: 1px solid $border; border-radius: 10px; max-width: 760px; margin: 40px auto; display: none;
			h4 { font-size: 19px; margin: 20px; }
			ul { list-style: square; 
				li { 
					a { border: none; 
						img { display: inline; width: 65px; margin: 0 0 0 2px; outline: none; }
					}
				}
			}
		}
		
		.roundup-more-info { padding: 0px 0 0 0; list-style: none; margin: 0 auto; max-width: 900px; 
			li { margin: 0 0 40px 0;  
				.sticky-roundup-companies { padding: 20px 0; backdrop-filter: blur(12px); -moz-backdrop-filter: blur(12px); -webkit-backdrop-filter: blur(12px); background: rgba(255,255,255,0.9); position: relative; z-index: 1000; display: grid; grid-template-columns: 2.5fr 0.7fr 1.2fr; column-gap: 20px; align-items: center; justify-items: flex-start;
					h2 { margin: 0px auto; text-align: left; width: 100%; font-size:32px; }
					img.stars { width: 100%; margin: 0; outline: none; }
					.cta { padding: 7px 2.5%; font-weight: 500; width: 90%; color: #fff; border-bottom: 0; text-decoration: none; border-radius: 50px; }
				}
				hr { }
				.inner { padding: 0px 0 30px 0; 
					p:first-child{ margin-top: 0;}
				}
				ul { list-style: square;  }
				ul,ol { margin-left: 10px; padding-left: 18px;
					li { margin: 10px 0 20px 0;  }
				}
			}
			li.star-3,li.star-2,li.star-1 { display: none; }
			li.roundup-not-hidden { display: block; }
			a#roundup-show-more { display: block; padding: 15px 0; color: $blue; border: 1px solid $blue; border-radius: 50px; text-align: center; font-size: 19px; font-weight: 600; }
		}
		
		.chapter { padding-top: 50px; 
		}
		
		table { border-collapse: collapse; width: 100%; max-width: 760px; margin: 0 0 20px 0; border-radius: 2px; overflow-x: auto; display: block;
			th,td { vertical-align: top; padding: 1.5% 3.5% 1.5% 1.5%; line-height: 165%; } 
			th { background-color: #fff; border-bottom: 2px solid $border; border-top: 2px solid $border; font-weight: 500; text-align: left; }
			td { font-size: 16px; }
			tr { border-bottom: 1px solid $border; }
			tr:hover { background: #FAFAFA; }
		}
		
	}
		
	.roundup-table { max-width: 800px; margin: 20px auto; text-align: center; background: #fff url('/assets/images/icons/top.png') no-repeat; background-size: 16px; background-position: 50% 85%; border: 1px dashed #000; border-radius: 4px; padding: 40px 20px 30px 20px;
		h2 { max-width: 700px; margin: 0 auto; text-align: center; font-size: 30px; }
		p.round-table-description { font-size:21px; margin: 5px auto; padding-bottom: 30px; text-align: center; }
		img.big-stars { outline: none; max-width: 120px; margin: 0 0 10px 0; }
		
		.table { margin: 20px 0 60px 0; border-bottom: 1px solid #ddd; text-align: left; 
			.row { display: grid; grid-template-columns: 1fr 0.75fr 2.75fr; column-gap: 10px;  text-align: left; align-items: flex-start; justify-items: flex-start; padding: 20px 10px;
				h4 { font-size: 17px; font-weight: 600; margin: 0; 
					a { color: $primary-color; text-decoration: underline; }
				}
				p { font-size: 16px; font-weight: 400; margin: 0; 
					a { color: $primary-color; text-decoration: none; }
				}
				img.stars { margin: 3px 0 0 0; width: 100%; max-width: 90px; outline: none; }
			}
			.row:hover { background: $lightest-blue; }
			.row:nth-child(even) { background: #f9f9f9; }
			.legend {padding: 20px 10px; border-bottom: 1px solid #999; border-top: 1px solid #ddd;
				h4 { font-size: 14px; text-transform: uppercase; font-weight: 700; }
			}
		}
	}
	

@media screen and (max-width:1250px) { 
	
	.roundup-title { background-size: 1296px 140.4px; 
		h1 { font-size: 48px; max-width: 640px; }
		h3 { font-size: 22px; max-width: 580px; }
	}
	
}

@media screen and (max-width:850px) { 

	.roundup-title { padding-top: 20px; width: 90%; margin: 0 5%; background: none;
		p.mobile-disclosure { display: block; background: #fff; text-align: center; line-height: 140%; margin: 5px 0; padding-bottom: 5px; border-radius: 3px; font-size: 12px; font-weight: 300;
			a { border-bottom: 1px dashed $primary-color; color: $primary-color; white-space: nowrap; }
		}
		h1 { font-size: 44px; margin-top: 10px; width: 100%; max-width: 600px; }
		h3 { font-size: 21px; margin: 10px auto; width: 100%; max-width: 600px; }
		.meta p { font-size: 12px; }
	}
	
	.roundup-content {
		
		.overview { 
			li { display: block; position: relative;
				h3 { width: 80%; padding: 15px 0; }
				p {  }
				img { position: absolute; top: 32px; right: 0; }
				.cta { display: block; margin: 15px 0; }
			}
			li.overview-not-hidden { display: block; }
		}
		
	}

}

@media screen and (max-width:950px) { 


	.roundup-table { padding-bottom: 40px; background-position: 50% 90%;
		h2 { font-size: 22px; }
		p.round-table-description { font-size: 17px; margin: 10px 0; padding-bottom: 0; }
		.table { padding: 10px 0; border-top: 1px solid $light-blue; border-bottom: none;
			.row { display: block; padding: 15px 0; position: relative; border-bottom: 1px solid $light-blue;
				img.stars { position: absolute; top:15px; right: 15px; }
				p { margin: 20px 0; }
			}
			.row:nth-child(even) { background: #fff; }
			.legend { display: none; }
		}
	}

	.roundup-toc span { font-size: 14px; background-size: 11px 11px; }

	.roundup-content { width: 90%; margin: 0 5%;

		h2 { font-size: 32px; }
		h3 { font-size: 22px; }
		
		.further { 
			a { margin: 2.5%; font-size: 17px; }
			span { font-size: 12px; }
		}

		
		.aside { padding: 5px 10px; }
		
		.mobile-table-of-contents { display: block; }

		.roundup-more-info {  
			li { 
				.sticky-roundup-companies { height: 40px; position: relative; display: block; padding: 10px 0;
					h2 { top: 70px; font-size: 16px; line-height: 100%; padding-top: 2px; }
					.cta { position: absolute; top: 10px; right: 0px; padding: 6px 10px; width: auto; font-size: 12px; min-width: 130px; display: inline-block; }
					.toggle-roundup { top: 50px; width: 10px; padding: 10px 5px; border: none; background-color: transparent; }
					img.stars { top: 30px; left: 0; width: 80px; right: auto; }
				}
				.inner { padding: 10px 0 30px 0; 
					p:first-of-type { font-size: 17px; 
						a,a.cta { font-size: 17px; }
					} 
					ul,ol { padding-left: 0; margin-left: 8%; width: 92%; }
					.pros-cons { grid-template-columns: 1fr; 
						ul { width: 100%; }
					}
				}
			}
		}

	}


}