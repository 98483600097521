.blog-splash { margin: 60px auto 80px; text-align: center;
	h1 { margin-bottom: 10px; font-size: 56px;  }
	h3 { font-size: 22px; margin-top: 0;
		a { text-decoration: underline; color: $primary-color; }
	}
	span.categories { 
		a { display: inline-block; background: $lightest-blue; padding: 5px 10px; border-radius: 10px; margin: 0 5px 10px 5px; color: $primary-color; font-size: 18px; transition: all 0.2s; -moz-transition: all 0.2s; -webkit-transition: all 0.2s; } 
		a:hover { background: $blue; color: #fff; transition: all 0.2s; -moz-transition: all 0.2s; -webkit-transition: all 0.2s; }
	}
}

ul.blog-index { list-style: none; margin: 40px auto; padding: 0; display: grid; grid-template-columns: 1fr 1fr 1fr; align-items: flex-start; justify-items: flex-end; grid-gap: 20px; column-gap: 40px; max-width: 1600px; width: 90%; text-align: left;
	li {
		a { display: block; color: $primary-color; width: 100%; 
			img { width: 100%; border-radius: 10px;  }
			h4 { border-bottom: 2px solid #fff; display: inline-block; font-size: 17px; font-weight: 500; margin: 10px 0 20px 0; transition: all 0.2s; -moz-transition: all 0.2s; -webkit-transition: all 0.2s; }
			p { margin: 0 0 20px 0; }
			button { margin-top: 5px; cursor: pointer; background: #fff; font-size: 17px; border: 3px solid #000; display: inline-block; padding: 10px 20px; color: #000; border-radius: 4px; font-weight: 500; }
			.video-thumb { position: relative; background: #000; border-radius: 10px;
				img#image { opacity: 0.85; display: block; transition: all 0.2s; -moz-transition: all 0.2s; -webkit-transition: all 0.2s; width: 100%; }
				#icon { background: $blue url('../images/play-circle.png') no-repeat; background-position: 50% 50%; background-size: 32px 32px; position: absolute; top: 50%; left: 50%; width: 100px; height: 50px; margin-left: -50px; margin-top: -25px; text-indent: -99999em; display: block; -moz-box-shadow: none; -webkit-box-shadow: none; box-shadow: none; }
			}
		}
	}
	li:first-child { grid-column: 1 / span 3; align-self: center; justify-self: flex-start;
		a { display: flex; justify-content: space-between; padding-bottom: 40px; gap: 40px; align-items: center;
			img { width: 60%; }
			.post-info { width: 35%;  
				h4 { font-size: 46px; font-weight: 700; }
				p { font-size: 21px; margin-top: 10px; }
			}
		}
	}
}

@media screen and (max-width: 1100px) { 
	
	ul.blog-index {  column-gap: 2.5%; grid-template-columns: 1fr 1fr; 
		li { width: 90%; margin: 0 5%; }
		li:first-child { grid-column: 1 / span 1; 
			a { display: block; padding-bottom: 0;
				img { width: 100%; }
				button { display: none; }
				.video-thumb { width: 100%; }
				.post-info { width: 100%;  
					h4 { font-size: 21px; font-weight: 500; }
					p { font-size: 17px; }
				}
			}
		}
	}
	
}


@media screen and (max-width:850px) { 
	
	ul.blog-index { margin: 50px 0;  grid-template-columns: 1fr; width: 100%; column-gap: 0;
		li.inspiration-title { grid-column: 1; align-self: center;  }
		li { width: 90%; margin: 0 5%; }
	}
	
}
