.google-cloud {
	max-width: 1000px;
	text-align: center;
	width: 90%;
	padding: 40px 5% 20px 5%;
	margin: 0 auto;

	.logo {
		display: flex;
		justify-content: center;

		a {
			width: 200px;
			display: block;

			img {
				width: 100%;
			}
		}

	}

	.gc-explainer {
		border-top: 1px solid #ddd;
		border-bottom: 1px solid #ddd;
		padding: 20px 0;
		margin-top: 40px;
		display: flex;
		text-align: left;
		gap: 20px;

		img {
			max-width: 300px;
		}

		h1 {
			font-weight: 500;
			font-size: 32px;
			line-height: 160%;
		}

		a {
			display: inline-block;
			border-radius: 40px;
			padding: 15px 30px;
			background: $blue;
			color: #fff;
			font-weight: 500;
		}

	}

	@media screen and (max-width: 992px) {
		padding: 20px 5%;
		.gc-explainer {
			display: block;
			text-align: center;
			padding-bottom: 40px;
			margin-top: 0;
			border-top: 0;

			img {
				max-width: 180px;
			}

			h1 { font-size: 22px; }
		}
	}

	.gc-content {
		text-align: left;
		max-width: 700px;
		margin: 0px auto;

		h2 {
			text-align: left;
			font-size: 21px;
			font-weight: 500;
			margin-top: 60px;
		}

		p {
			line-height: 160%;

			a {
				text-decoration: underline;
			}
		}
	}
	@media screen and (max-width: 992px) {
		.gc-content h2 { font-size: 18px; }
	}

}