.outro { border: 1px solid #ddd; padding: 2.5%; border-radius: 3px; margin-bottom: 30px;  text-align: center;
    h3 { font-size: 26px; margin: 0; font-weight: 700; line-height: 120%; }
    h4 { font-size: 17px; font-weight: 600; }
    p { font-size: 17px; margin: 15px 0; text-wrap: balance; line-height: 135%; }
    button { font-size: 17px; background: $lightest-blue; color: $blue; display: inline-flex; padding: 6px 12px; border-radius: 3px; gap: 3px; align-items: center; font-weight: 600; border: none; cursor: pointer;
        svg { width: 18px; height: 18px; }
    }
}

@media screen and (max-width:960px) { 
    .outro {  padding: 5% 2.5%; 
        h3 { font-size: 21px; }
        p { font-size: 15px; }
    } 
}


// How to Make

.outro-how-to-make { padding: 30px 90px 0 90px; display: grid; grid-template-columns: 1fr 1fr; gap: 30px; overflow: hidden;
    img { width: 100%; transition: transform 0.5s; -webkit-transition: transform 0.5s; } 
    .text { text-align: center; padding: 2.5%; align-self: center; justify-content: center;
        a { display: block; color: #000; transition: color 0.5s; transition: color 0.5s; } 
    }
}
.outro-how-to-make:hover img { transform: scale(1.1); }

@media screen and (max-width:1200px) { 
    .outro-how-to-make { display: flex; flex-direction: column-reverse; gap: 10px; }
}

@media screen and (max-width:1000px) { 
    .outro-how-to-make { padding: 30px 30px 0 30px; }
}

// Templates

.outro-templates { 
    ul { display: grid; grid-template-columns: 1fr 1fr 1fr; gap: 30px; margin: 30px 0 0 0; align-content: center;; list-style: none; padding: 0; 
        img { width: 100%; border: 1px solid #ddd; border-radius: 3px; }
        h4 { color: #000; margin: 5px 0 0  0; font-size: 17px; }
        a img { transition: transform 0.5s; -webkit-transition: transform 0.5s;  }
        a:hover img { transform: scale(1.03); }
    }
}


@media screen and (max-width:700px) { 
    .outro-templates ul {  grid-template-columns: 1fr 1fr; 
        h4 { font-size: 15px; }
    }
}

// Comparison

.outro-comparison { display: grid; grid-template-columns: 1fr 1fr 1fr; gap: 30px;  
    .outro { padding: 5%; 
        a { align-content: center; display: block;
            .icons { margin: 0 auto 20px auto; display: flex; max-width: 200px; justify-content:center; align-content: center; flex-wrap: wrap; gap: 15px; transition: gap 0.5s; transition: gap 0.5s;
                img { height: 40px; width: 40px; padding: 15px; border: 1px solid #ddd; border-radius: 3px; -webkit-box-shadow: 0 5px 5px 0 #ddd; box-shadow: 0 5px 5px 0 #ddd; -moz-box-shadow: 0 5px 5px 0 #ddd; transition: transform 0.5s; -webkit-transition: transform 0.5s; }
            }
            h3 { color: #000; margin-bottom: 10px; }
        }
    }
    .outro:hover { 
        a .icons { gap: 5px; }
        a .icons img:first-child { transform: rotate(10deg); }
        a .icons img:last-child {  transform: rotate(-10deg); }
    }
}

@media screen and (max-width:1000px) { 
    .outro-comparison { display: block; }
}

// Best Tool

.outro-best-tool { display: grid; grid-template-columns: 1fr 1.5fr 1fr; gap: 60px; 

    .icons { display: flex; justify-content: space-between; align-content: center; flex-wrap: wrap; gap: 5px;
        img { height: 40px; width: 40px; padding: 15px; border: 1px solid #ddd; border-radius: 3px; -webkit-box-shadow: 0 5px 5px 0 #ddd; box-shadow: 0 5px 5px 0 #ddd; -moz-box-shadow: 0 5px 5px 0 #ddd; transition: margin-top 0.5s; -webkit-transition: margin-top 0.5s; }
    }
    .text { text-align: center; 
        a { display: block; color: #000; transition: color 0.5s; transition: color 0.5s; } 
    }

}
.outro-best-tool:hover { 
    .icons img { margin-top: 0 !important; }
}

@media screen and (max-width:1500px) { 
      .outro-best-tool  .icons img { width: 30px; height: 30px; }
}

@media screen and (max-width:1250px) { 
    .outro-best-tool  { grid-template-columns: 1fr 1.3fr 1fr; }
}


@media screen and (max-width:1160px) { 
    .outro-best-tool { display: block; 
        .text { max-width: 600px; margin: 0 auto; }
        .icons:first-child { max-width: 200px; margin: 0 auto 20px auto;
            img { margin-top: 0 !important; padding: 5px; border: none; box-shadow: none; -webkit-box-shadow: none; -moz-box-shadow: none; }
        }
        .icons:last-child { display: none; }
    }
}
  