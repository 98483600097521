.splash-quote { text-align: center; font-size:24px; padding: 2.5% 5%; margin: 0 auto; border: 0; background: $lightest-blue; font-style: normal; margin-bottom: 30px; font-weight: 400; max-width: 1000px;  } 

.page { width: 90%; padding: 2.5% 5%; max-width: 800px; text-align: left; margin: 0 auto; 


	h2 { margin-top: 40px; }
	p,ul { line-height: 180%; font-size: 18px; font-weight: 300; }
	p a, ul a { border-bottom: 1px solid $blue; padding-bottom: 2px; }
	blockquote { margin-left: 0; font-size: 22px;  }

	ul.contributors { list-style: none; margin: 0; padding: 0; 
		li { display: inline-block; width: 150px; text-align: center;
			a { border-bottom: none; padding-bottom: 0; color: $primary-color; }
			img { max-width: 100px; border-radius: 100%; border: 1px solid $border; }
			h5 { font-weight: 500; margin: 0 0 30px 0; }
		}
	}

	.image-right { float: right; margin: 0 0 30px 60px; 
		img { -webkit-box-shadow: none; -moz-box-shadow: none; box-shadow: none; max-width: 300px; }
		p.caption { margin: 0; text-align: center; font-size: 13px; color: $secondary-color; }
	}
	
	.quiz-widget { width: 100%; border-radius: 5px; box-shadow: 0px 10px 20px #ddd; -moz-box-shadow: 0px 10px 20px #ddd; -webkit-box-shadow: 0px 10px 20px #ddd; 
		.typeform-widget { height: 700px;  }
		form.quiz-feedback { background: #1841a6; padding-top: 30px; border-radius: 0 0 5px 5px;
			.field { display: block; position: relative; height: 80px;
				label { position: absolute; top: 4px; font-size: 17px; left: 2.5%; width: 29%; color: #e8ecf6; }
				input,textarea { border-radius: 4px; position: absolute; top: 0px; left: 35%; width: 39%; padding: 1%; background: #e8ecf6; font-size: 16px; outline: none; border: none; font-family: "Gotham SSm A", "Gotham SSm B", sans-serif; }
				button { position: absolute; top: 0px; right: 2.5%; background: $blue; width: 20%; padding: 2.5% 1.5%; font-weight: 700; color: #fff; font-size: 15px; border: none; cursor: pointer; border-radius: 4px; }
			}
		}
	}
	
}

.author-bio { max-width: 650px; margin: 40px auto; text-align: center;
	
	img { max-width: 180px; border: 9px solid #eee; border-radius: 100%; margin: 0 auto; }
	h1 { font-size: 36px;}
	p { 
		a { border-bottom: 1px solid $blue; padding-bottom: 2px; }
	}
}


.all-content { padding: 80px 0;  text-align: center;

	h2 { font-size: 34px; }
	p { max-width: 800px; font-size: 19px; margin: 0 auto 50px auto;  
		a { color: $primary-color; border-bottom: 1px solid $blue; padding-bottom: 2px;}
	}
	h4 { text-transform: uppercase; font-size: 14px; font-weight: 600; text-align: left; }
	ul { list-style: none; margin: 0 0 40px 0; padding: 0; text-align: left; 
		a { display: block; color: $primary-color; border-bottom: 1px solid $light-blue; padding-bottom: 2px; margin-bottom: 3px; font-size: 16px; font-weight: 400;
			span { display: none; }
			span.popular { display: inline; color: $blue; text-transform: uppercase; font-size: 10px; font-weight: 700; margin-left: 2px; }
		}
		a:hover { color: $blue; 
			span { display: inline; }
			span.popular { color: $blue;  }
		}
	}
	.three-col-left { float: left; width: 28%; margin-right: 5%; }
	.three-col-mid { float: left; width: 28%; margin-right: 5%; }
	.three-col-right { float: right; width: 28%;  }
	
}


@media screen and (max-width: 992px) {

	.splash-quote { text-align: left;  } 


	.page {
	
		.image-right { display: block; float: none; margin: 0; text-align:center; }
		
		.quiz-widget .typeform-widget { height: 400px;  }
		.quiz-widget form.quiz-feedback { background: #f1f1f1;}
		.quiz-widget form.quiz-feedback .field { height: 120px;
			label { text-align:center; color: $primary-color; position: relative; top: auto; left: auto; display: block; width: 95%; margin: auto 2.5%; }
			textarea { border: 1px solid #ddd; background:#fff; top: 35px; left: 2.5%; width: 60%; height: 44px; }
			button { border: 1px solid #ddd; background:#fff; color: $primary-color; font-weight: 400; top: 35px; right: 2.5%; width: 30%; }
		}
	
	}

	.all-content { 
		.three-col-left, .three-col-mid, .three-col-right { float: none; width: 100%; margin: 0;  }
	}

}