.navigator { list-style: none; margin: 40px 0; padding: 0;
	li.one { float: left; }
	li.two { float: right; }
	li { width: 38%; padding: 3% 4%; margin: 2%; border-radius: 2px; box-shadow: 0px 0px 0 2px #eee; -webkit-box-shadow: 0px 0px 0 2px #eee; -moz-box-shadow: 0px 0px 0 2px #eee;
		h5 { color: $secondary-color; text-transform: uppercase; font-size: 11px; font-weight: 700; margin: 0; }
		h2 { margin: 10px 0;}
		p a, ul a, ol a {	border-bottom: 1px solid $light-blue; padding-bottom: 3px; }
		p { font-size: 19px; line-height: 170%; }
		a { color: $primary-color;  }
		a:hover { color: $blue; }
		hr { border: none; height: 1px; margin: 20px 0; background: #ddd; }
		ul,ol { list-style: none; margin: 20px 0; padding: 0 0 0 0px;
			li { width: 100%; padding: 0; margin: 0 0 5px; border: none; box-shadow: none; -webkit-box-shadow: none; -moz-box-shadow: none; 
				img { max-width: 80px; margin-left: 5px; line-height: 10%; }
			}
		}
		ol { list-style: decimal;  padding-left: 23px; }
		
	}	
}


@media screen and (max-width:850px) { 

	.navigator {
		li.one { float: none; }
		li.two { float: none; }
		li { width: 90%; padding: 5%; margin: 20px 0;
			h2 { font-size: 30px; }
			p { font-size: 17px; }
		}
	}
  
}
