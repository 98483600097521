.comparison {

	h1 { font-size: 52px; margin: 60px 0 0 0; }
	h3 { margin-top: 20px; font-size: 24px; margin-bottom: 70px;}

	table#comparison-table { width: 100%; text-align: left; margin-top: 50px; border-collapse: collapse;
		tr.sticky-bar { background: #fff;}
		.legend {  width: 11%; }
		.cell { width: 18.5%; }
		td,th { vertical-align: top; padding: 1.5%;
			h2 { text-align: center; font-size: 28px; margin: 3px 0; }
			h4 { margin: 4px 0 0 0; text-transform: uppercase; font-size: 15px; line-height: 19px; text-align: right; }
			p { margin: 0px; }
			p.overview { font-size: 19px; line-height: 180%; }
			h1 { text-align: center; margin: 70px 0 30px 0; border-bottom: 1px solid $light-border; padding-bottom: 35px; font-size: 52px; }
			ul { list-style: none; margin: 0; padding: 0;
				li { margin-bottom: 35px; }
				h4 { text-align: left; margin-bottom: 7px; }
			}
			img.stars {margin: 0 0 10px 0; max-width: 160px; }
			hr { background: $light-border; }
			strong { text-transform: uppercase; font-weight: 600; }
			span.checkmark { margin-bottom: 10px; display: inline-block; padding-left: 28px; background:url("../images/icons/checkmark.png"); background-size: 16px; background-repeat: no-repeat; background-position: 0px 5px; height: 24px; text-transform: uppercase; font-weight: 600; }
			span.x { margin-bottom: 10px; display: inline-block; padding-left: 28px;background:url("../images/icons/x.png"); background-size: 16px; background-repeat: no-repeat; background-position: 0px 5px; height: 24px; text-transform: uppercase; font-weight: 600; }

		}
		td {
			a.theme-example img { width: 100%; margin-bottom: 10px; max-width: 300px; -webkit-box-shadow: 0 0 5px #ddd; -moz-box-shadow: 0 0 5px #ddd; box-shadow: 0 0 5px #ddd; }
			a.screenshot-left img,a.screenshot-right img { width: 100%; max-width: 120px;}
			a.screenshot-left { display: inline-block; width: 49%; float: left; clear: both; }
			a.screenshot-right { display: inline-block; width: 49%; float: right;  }
		}
		th { text-align: center;
			.cta { width: 100%; padding: 10px 0; margin: 15px 0; }
			img { width: 100%; max-width: 160px; }
		}
		tr:hover td { background: $light-border; }
	}

}

@media only screen and (min-device-width : 320px) and (max-device-width : 480px) {

	.comparison {

		.subheader { display: none; }

		table#comparison-table { overflow-x: scroll; width: 900px;
			td.legend { width: 100px;  }
			td.cell { width: 100px; }
			td,th {
				h4 { font-size: 13px; text-align: left; }
				p.overview { font-size: 16px; }
			}
		}

	}

}