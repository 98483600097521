.state-of { text-align: center; 

	.title { text-align: center; margin: 10px auto 70px auto; max-width: 1000px;

		h1 { margin: 5px 0;  }
		h3 { margin: 20px 60px;
			a { color: $primary-color; border-bottom: 1px solid $light-blue;}
		}
		.published { position: relative; height: 80px; text-align: center;
			p { margin: 0; font-size: 16px; line-height: 140%; font-weight: 400;
				span { display: block; font-size: 13px; color: $secondary-color; font-weight: 300; }
				a { color: $primary-color; border: none; }
			}
			#date { float: left; width: 50%; text-align: right; position: absolute; top: 10px; left: 0; 
				p { margin: 10px 65px 0 0;}
			}
			#author { float: right; width: 50%; text-align: left; position: absolute; top: 10px; right: 0; 
				p { margin: 10px 0 0 65px; }
			}
			img { border-radius: 100%; max-width: 80px; }
		}
			
	}

	.splash { margin-bottom: 250px; padding: 30px 0 150px 0; 
		img#logo { max-width: 212px; margin: 30px 0 70px 0; }	
	}
	
	h4 { text-transform: uppercase; font-weight: 1000; font-size: 16px;}
	h2 { font-size: 39px; font-weight: 1000;}
	p,ul,ol { font-size: 19px; line-height: 200%; font-weight: 400; 
		span { color: $secondary-color; font-size: 13px; margin-left: 5px; font-weight: 300; }
		strong{ font-weight: 1000;}
		a { font-weight: 500; border-bottom: 2px solid $blue; padding-bottom: 2px; color: $blue; }
	}

	.insights { max-width: 600px; margin: 0 auto; text-align: left; padding-bottom: 100px;
		h2 { text-align: center; margin-top: 100px; }
		p { margin: 40px 0;
			a { font-weight: 500; border-bottom: 2px solid $blue; padding-bottom: 2px; color: $blue; }
		}
	}

	.card { padding: 100px 0; text-align: center;
		
		h4 { margin-bottom: 15px; }
		h2 { margin: 15px 0;}
		p { margin: 20px auto 35px auto; max-width: 950px; }
	
		ul.trend { list-style: none; margin: 5% 0; padding: 0; text-align: center;
			li { width: 29%; margin: 3% 1.5%; text-align: center; display: inline-block; background: $lightest-blue; padding-top: 40px; border-radius: 10px;
				span { color: $blue; font-size: 52px; font-weight: 700; }
				span.green { color: #00E676; }
				h2 { font-size: 28px; }
				p { font-size: 15px; }
			}
		}
		
	}
	

}

@media screen and (max-width: 992px) { 

	.state-of {
		
		.wide-wrapper { width :100%; margin: 0;}
		
		.title { margin-top: 30px;
			h1 { font-size: 44px; }
			h3 { font-size: 21px; margin: 20px 0; }
			.published { 
				p { font-size: 13px; 
					span { font-size: 11px; }
				}
				#date p { margin: 10px 55px 0 0; }
				#author p { margin: 10px 0 0 55px; }
			}
		}
		.title-with-margin { margin-top: 20px; }
		
		.splash .wrapper { width: 100%; margin: 0; }
		
		.splash { padding: 30px 5% 150px 5%; width: 90%; }
		.insights { width: 90%; margin: 0 5%;  }
		.card { width: 95%; margin: 0 2.5%;
			ul.trend {
				li { width: 100%; margin: 5% 0; }
			}
		}
		
	}

}