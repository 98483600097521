// Article Subheader WITH CTAs

.article-subheader-with-ctas { position: relative; width: 100%;  z-index: 1000; margin-bottom: 3px; backdrop-filter: blur(12px); -moz-backdrop-filter: blur(12px); -webkit-backdrop-filter: blur(12px); background: rgba(255,255,255,0.75);

	.inner { display: flex; padding: 10px 0; justify-content: space-between; gap: 5px; align-items: center; max-width: 1600px; margin: 0 auto; width: 90%; position: relative; text-align: left; z-index: 122000;
		
		ul.breadcrumbs { list-style: none; margin: 0; padding: 0;
			li { margin-right: 1px; padding-right: 18px; display: inline-block; background: url('/assets/images/icons/breadcrumb.png') no-repeat; background-size: 12px 12px; background-position: 100% 50%; 
				a { color: $primary-color; font-size: 15px; font-weight: 400; }
			}
			li:last-child { background-image: none; }
		}
	
		a.cta { margin-left: 10px; padding: 17px 25px; font-size: 15px; border-radius: 40px; }

	}

}

@media screen and (max-width: 1000px) { 

	.article-subheader-with-ctas {  
		.inner { width: 100%; margin: 0; padding: 0;
			ul.breadcrumbs { display: none; }
			.ctas { display: flex; width: 100%; gap: 0px;
				a.cta { width: 100%; display: block; height: 18px;margin-left: 0; font-size: 15px; border-radius: 0; background: none; color: $blue; border-bottom: 1px solid $blue; border-left: 0.5px solid $blue; border-right: 0.5px solid $blue; border-top: 1px solid $blue; }
			}
		}
	}

}



// Article Subheader WITHOUT CTAs

.article-subheader-no-ctas { position: relative; display: flex; background: #fff; padding: 15px 0; min-height: 45px; width: 100%; justify-content: space-between; align-items: center;
	
	a.back { color: $primary-color; font-size: 15px; padding: 0px 0 0px 20px; background: url("../images/icons/back.png"); background-size: 12px 12px; background-repeat: no-repeat; background-position: 0 50%; }
	
	p.affiliate-disclosure { margin: 0; padding: 10px 15px; font-size: 13px; font-weight: 400;  background: #fff; border: 1px solid $border; border-radius: 3px; 
		a { text-decoration: none; border-bottom: 1px dashed #000; padding-bottom: 2px; color: #000; }
	}

}

@media screen and (max-width: 850px) { 

	.article-subheader-no-ctas { 
		a.back { display: none; }
		p.affiliate-disclosure { display: block; text-align: center; }
	 }

}


// Inspiration Subheader

.inspiration-subheader { position: relative; display: flex; background: #fff; padding: 5px 0 15px 0; min-height: 45px; width: 100%; justify-content: space-between; align-items: center;
	
	a.back { display: flex; align-items: center; color: $primary-color; font-size: 13px; 
		svg { width: 18px; height: 18px; }
	}
	
	p.affiliate-disclosure { margin: 0; font-size: 13px; font-weight: 400;  
		a { text-decoration: none; border-bottom: 1px dotted #000; padding-bottom: 2px; color: #000; }
	}


}

@media screen and (max-width: 850px) { 

	.inspiration-subheader { 
		a.back { display: none; }
		p.affiliate-disclosure { width: 100%; text-align: center; }
	}

	.two-ctas {
		a.back { display: none; }
		a:last-of-type { float: left; margin-left: 0; }
	}

}