span.template-categories {  display: block;
	a { display: inline; white-space: nowrap; background: $lightest-blue; padding: 5px 10px; line-height: 250%; border-radius: 10px; margin: 0 5px 15px 5px; color: $primary-color; font-size: 16px; transition: all 0.2s; -moz-transition: all 0.2s; -webkit-transition: all 0.2s; } 
	a:hover { background: $blue; color: #fff; transition: all 0.2s; -moz-transition: all 0.2s; -webkit-transition: all 0.2s; }
	a.active { background: $blue; color: #fff; transition: all 0.2s; -moz-transition: all 0.2s; -webkit-transition: all 0.2s; }
}

.related-template-articles { margin-top: 30px; padding: 30px 0;
	ul.templates-index li a h4 { border-bottom: 0px; }
}

.templates { list-style: none; border-top: 1px solid #eee; border-bottom: 1px solid #eee; padding: 20px 0; margin: 5vh 0 5vh 0; display: grid; grid-template-columns: 1fr 1fr; gap: 60px;

    li { 
        a.templates-cta { display: block; position: relative; 
            img { width: 100%; border: 1px solid #ddd; }
            .info { display: flex; align-items: flex-start; justify-content: space-between; opacity: 0; width: 92%; position: absolute; border: 1px solid #ddd; bottom: 7.5%; left: 2.5%; background: rgba(255, 255, 255, 0.8); padding: 1.5%; border-radius: 6px; backdrop-filter: blur(10px); -webkit-backdrop-filter: blur(10px); transition: opacity 0.5s; -moz-transition: opacity 0.5s; -webkit-transition: opacity 0.5s;
                h3 { line-height: 120%; margin: 0; font-size: 17px; color: #000; font-weight: 700;  }                
				p { margin: 0; font-size: 15px; color: #000; }
				span.button { font-size: 15px; font-weight: 700; display: inline-block; background: $blue; padding: 10px 20px; border-radius: 3px; color: #fff; }
            }
        }
        a.templates-cta:hover {  
            .info { opacity: 1;}
        }
    }

}


@media screen and (max-width: 800px) {

    .templates { grid-template-columns: 1fr; gap: 40px; 
        li { 
            a.templates-cta { 
                .info { opacity: 1; position: relative; width: 100%; bottom: auto; left: auto; border: none; padding: 0; border-radius: 0px; margin: 5px 0 25px 0; 
					span.button { font-size: 13px; min-width: 95px; text-align: center; }
				}
            }
        }
    }
	
}
