.email-articles-list { list-style: none; padding: 0; width: 100%; margin: 40px 0 40px 0; display: grid; grid-template-columns: 1fr 1fr 1fr 1fr; gap: 20px;
    li { text-align: center;
        h4 { margin-top: 5px; font-size: 19px; }
        img { width :100%; }
        a { color: #000; }
    }
}

.email-templates { list-style: none; border-top: 1px solid #eee; border-bottom: 1px solid #eee; padding: 20px 0; margin: 5vh 0 5vh 0; display: grid; grid-template-columns: 1fr 1fr 1fr 1fr; gap: 60px;

    li { 
        a.email-templates-cta { display: block; position: relative; 
            img { width: 100%; border: 1px solid #ddd; }
            .info { opacity: 0; width: 80%; position: absolute; border: 1px solid #ddd; bottom: 7.5%; left: 5%; background: rgba(255, 255, 255, 0.8); padding: 5%; border-radius: 10px; backdrop-filter: blur(10px); -webkit-backdrop-filter: blur(10px); transition: opacity 0.5s; -moz-transition: opacity 0.5s; -webkit-transition: opacity 0.5s;
                h3 { line-height: 120%; margin: 10px 0 0 0; font-size: 15px; color: #000; font-weight: 600;  }                
                .logo { max-width: 90px; display: flex; align-items: center;
                    img { width: 100%; border: none; }
                }            
                hr { margin: 15px 0; }
                p { color: #000; font-size: 13px; margin: 0px; }        
            }
        }
        a.email-templates-cta:hover {  
            .info { opacity: 1;}
        }
    }

}


@media (max-width:1600px) {

	.email-templates { grid-template-columns: 1fr 1fr 1fr;  }

}

@media (max-width:1100px) {

    .email-articles-list { grid-template-columns: 1fr 1fr 1fr; }
	.email-templates { grid-template-columns: 1fr 1fr; gap: 40px;  }

}

@media screen and (max-width: 800px) {

    .email-articles-list { grid-template-columns: 1fr 1fr; }

    .email-templates { grid-template-columns: 1fr; gap: 40px; 
        li { 
            a.email-templates-cta { 
                .info { opacity: 1; position: relative; width: 100%; bottom: auto; left: auto; border: none; padding: 0; border-radius: 0px; margin: 5px 0 25px 0; }
            }
        }
    }
	
}
