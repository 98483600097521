

.title-with-margin { margin-top: 50px; }

@media screen and (max-width:850px) {


}


//.title { text-align: center; margin: 10px auto; max-width: 1000px;
//
//	img.stars { max-width: 170px; }
//	h1 { margin: 5px 0;  }
//	h3 { margin: 20px 60px;
//		a { color: $primary-color; border-bottom: 1px solid $light-blue;}
//	}
//	.published { position: relative; height: 80px; text-align: center;
//		p { margin: 0; font-size: 16px; line-height: 140%; font-weight: 400;
//			span { display: block; font-size: 13px; color: $secondary-color; font-weight: 300; }
//			a { color: $primary-color; border: none; }
//		}
//		#date { float: left; width: 50%; text-align: right; position: absolute; top: 10px; left: 0;
//			p { margin: 10px 65px 0 0;}
//		}
//		#author { float: right; width: 50%; text-align: left; position: absolute; top: 10px; right: 0;
//			p { margin: 10px 0 0 65px; }
//		}
//		img { border-radius: 100%; max-width: 80px; }
//	}
//
//	.screenshots { margin: 10px auto 20px auto; text-align: center;
//		img { border-radius: 3px; box-shadow: 0 3px 4px #ccc; -webkit-box-shadow: 0 3px 4px #ccc; -moz-box-shadow: 0 3px 4px #ccc; }
//		.one { max-width: 250px; margin-bottom: 12px; margin-right: 10px; }
//		.two { max-width: 300px; }
//		.three { max-width: 250px; margin-bottom: 12px; margin-left: 10px; }
//	}
//
//}
//
//.title-with-margin { margin-top: 50px; }
//
//@media screen and (max-width:950px) {
//
//	.title .screenshots {
//		.one { max-width: 30%; }
//		.two { max-width: 35%; }
//		.three { max-width: 30%; }
//	}
//
//}
//
//@media screen and (max-width:850px) {
//
//	.title { margin-top: 30px;
//		img.stars { max-width: 130px; }
//		h1 { font-size: 44px; }
//		h3 { font-size: 21px; margin: 20px 0; }
//		.screenshots {
//			.one, .three { display: none; }
//			.two { max-width: 100%; width: 100%; }
//		}
//		.published {
//			p { font-size: 13px;
//				span { font-size: 11px; }
//			}
//			#date p { margin: 10px 55px 0 0; }
//			#author p { margin: 10px 0 0 55px; }
//		}
//	}
//	.title-with-margin { margin-top: 20px; }
//
//
//}