$primary-color: #151515;
$secondary-color: #999;
$blue: #2962FF;
$dark-blue: #0F4CF4;
$light-blue: #cfdcff;
$lightest-blue: #eaeffe;
$light-green: #F8FEFB;
$green: #0cce70;
$darker-green: #0eae60;

$border: #E3E8ED;
$light-border: #f7f7f7;

body {
	font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;
	color: $primary-color;
	font-size: 17px;
}

body.data-and-trends {
	background: #ffe1ce;
}

a {
	color: $blue;
	text-decoration: none;
}

.clear {
	clear: both;
}

h1 {
	font-size: 52px;
	line-height: 115%;
	font-weight: 700;
}

h2 {
	font-size: 30px;
	line-height: 130%;
	font-weight: 700;
}

h3 {
	font-size: 23px;
	line-height: 160%;
	font-weight: 400;
}

h4 {
	font-size: 21px;
	line-height: 130%;
	font-weight: 700;
}

hr {
	margin: 20px auto;
	height: 1px;
	border: none;
	background: $border;
}

p,
ul,
ol,
blockquote {
	font-weight: 300;
	font-size: 17px;
	line-height: 160%;
}

blockquote {
	font-style: italic;
	padding-left: 20px;
	border-left: 1px solid $primary-color;
}

a.cta {
	display: inline-block;
	font-size: 14px;
	padding: 14px 20px;
	text-align: center;
	border-radius: 5px;
	font-weight: 500;
	color: #fff;
	background: linear-gradient($blue, $dark-blue);
	-moz-background: linear-gradient($blue, $dark-blue);
	-webkit-background: linear-gradient($blue, $dark-blue);
	transition: all 0.3s;
	-moz-transition: all 0.3s;
	-webkit-transition: all 0.3s;
}

a.cta:hover {
	color: #fff;
	background: linear-gradient($dark-blue, $dark-blue);
	-moz-background: linear-gradient($dark-blue, $dark-blue);
	-webkit-background: linear-gradient($dark-blue, $dark-blue);
}

a.secondary-cta {
	display: inline-block;
	padding: 20px;
	border-radius: 5px;
	font-weight: 500;
	color: $blue;
	background: $lightest-blue;
}

@media screen and (max-width:900px) {

	a.cta {
		font-size: 13px;
		padding: 10px 15px;
	}

}

// YouTube

.youtube {
	margin: 20px 0;

	h2 {
		margin-bottom: 10px;
	}

	a.youtube-video {
		position: relative;
		display: block;
		transition: opacity 0.3s;
		-moz-transition: opacity 0.3s;
		-webkit-transition: opacity 0.3s;

		img.thumb {
			width: 100%;
			display: block;
			margin: 0;
			border-radius: 2px;
			border: none !important;
			box-shadow: none !important;
			-webkit-box-shadow: none !important;
			-moz-box-shadow: none !important;
			background: linear-gradient(180deg, rgba(0, 0, 0, 0) 44%, rgba(0, 0, 0, 1) 100%);
		}

		img.icon {
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
			width: 80px !important;
			margin: 0 !important;
			border: none !important;
			box-shadow: none !important;
			-webkit-box-shadow: none !important;
			-moz-box-shadow: none !important;
		}
	}

	a.youtube-video:hover {
		opacity: 0.8;
	}
}

.youtube-embed {
	width: 100%;
	max-width: 760px;
	height: 427px;
}

@media screen and (max-width:900px) {

	.youtube-embed {
		height: 230px;
	}

	.youtube a.youtube-video {
		img.icon {
			width: 40px !important;
		}
	}
}

// Toggles

.collapsed {
	.toggle {
		cursor: pointer;
		background: url('/assets/images/icons/breadcrumb.png') no-repeat;
		transition: opacity 0.2s;
		-webkit-transition: opacity 0.2s;
		-moz-transition: opacity 0.2s;
	}

	.toggle:hover {
		color: $blue;
		background-image: url('/assets/images/icons/breadcrumb-blue.png');
	}

	.toggled {
		background: url('/assets/images/icons/toggled.png') no-repeat;
	}

	.inside {
		display: none;

		p,
		ul,
		ol {
			font-size: 16px;

			.cta {
				font-size: 16px;
			}
		}
	}
}

.collapsed-button {
	margin: 20px 0;
	padding: 20px 40px;
	border-radius: 10px;
	border: 1px solid $blue;

	h2 {
		font-size: 17px !important;
		font-weight: 600 !important;
	}

	h2.toggle {
		padding-left: 40px;
		background-size: 20px 20px;
		background-position: 0% 50%;
		background-repeat: no-repeat;
	}
}

.collapsed-line {
	margin: 0;
	padding: 5px 0px;
	border-bottom: 1px solid $primary-color;

	h2 {
		font-size: 16px !important;
		font-weight: 600 !important;
	}

	h2.toggle {
		padding-left: 30px;
		background-size: 16px 16px;
		background-position: 0% 2px;
	}

	.inside {
		padding: 20px 0;

		p:first-child {
			margin-top: 0;
		}
	}
}

// Pros & Cons

.pros-cons {
	display: grid;
	grid-template-columns: 1fr 1fr;
	width: 100%;
	gap: 20px;

	h3 {
		border-bottom: 1px solid $blue;
		padding-bottom: 20px;
	}

	ul {
		list-style: none !important;
		margin: 0 !important;
		padding: 0 !important;

		li {
			border-bottom: 1px solid #000;
			padding: 0 0 10px 0;
			font-size: 16px;
			margin: 10px 0 20px 0;

			strong {
				display: block;
				background: url('/assets/images/icons/breadcrumb.png') no-repeat;
				transition: opacity 0.2s;
				-webkit-transition: opacity 0.2s;
				-moz-transition: opacity 0.2s;
				padding-left: 30px;
				background-size: 16px 16px;
				background-position: 0% 5px;
				cursor: pointer;
				font-weight: 500;
			}

			strong.toggled {
				background: url('/assets/images/icons/toggled.png') no-repeat;
				background-size: 16px 16px;
				background-position: 0% 5px;
				background-repeat: no-repeat;
			}

			strong:hover {
				color: $blue;
				background-image: url('/assets/images/icons/breadcrumb-blue.png');
			}

			p {
				margin: 20px 0;
				display: none;
				font-size: 16px;
			}
		}
	}
}

// Line Title

.line-title {
	font-size: 35px;
	margin-top: 80px;
}

@media screen and (max-width:950px) {
	.line-title {
		font-size: 32px;
	}
}


// Gradient

.gradient {
	background: #3D4CF4;
	background: linear-gradient(to right, #3D4CF4 0%, #ff2862 100%);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}

// Newsletter

.newsletter {
	padding: 5%;
	background: #F9FAFF;
	border-radius: 5px;
	margin-bottom: 40px;

	p {
		margin: 0 auto;

		strong {
			color: $blue;
		}
	}

	#mc_embed_signup {
		width: 100%;
		position: relative;
		margin-top: 20px;

		label {
			display: none;
		}

		input.email {
			width: 92%;
			font-size: 17px;
			padding: 4%;
			border-radius: 50px;
			background: #fff;
			border: none;
		}

		input.button {
			-webkit-appearance: none;
			cursor: pointer;
			position: absolute;
			top: 7px;
			right: 13px;
			font-size: 17px;
			font-weight: 900;
			width: 150px;
			background: $blue;
			color: #fff;
			padding: 15px 10px;
			border-radius: 50px;
			border: none;
		}
	}
}

@media screen and (max-width:900px) {

	.newsletter {
		#mc_embed_signup {
			input.email {
				border-radius: 5px;
				border: 1px solid $blue;
			}

			input.button {
				position: relative;
				top: auto;
				right: auto;
				border-radius: 5px;
				display: block;
				width: 100%;
				text-align: center;
				margin-top: 4px;
			}
		}
	}

}