.category { list-style: none; margin: 40px auto; padding: 0; display: grid; grid-template-columns: 1fr 1fr 1fr; align-items: flex-start; justify-items: flex-start; grid-gap: 20px; column-gap: 40px; max-width: 1600px; width: 90%; text-align: left;
	li.category-title { grid-column: 1 / span 2; align-self: center;  }
	li { 
		h1 { margin-top: 0; }
		a { display: block; color: $primary-color; width: 100%; 
			img { width: 100%; border-radius: 3px; -webkit-box-shadow: 0 0 6px #eee; -moz-box-shadow: 0 0 6px #eee; box-shadow: 0 0 0 6px #eee; transition: all 0.2s; -moz-transition: all 0.2s; -webkit-transition: all 0.2s;  }
			h4 { border-bottom: 2px solid #fff; display: inline-block; font-weight: 500; margin: 20px 0; transition: all 0.2s; -moz-transition: all 0.2s; -webkit-transition: all 0.2s; }
		}
		a:hover {
			h4 { color: $blue; }
			img { -webkit-box-shadow: 0 0 6px $blue; -moz-box-shadow: 0 0 6px $blue; box-shadow: 0 0 0 6px $blue; }
		}
	}
}



@media screen and (max-width: 840px) {

	.category { margin: 50px 0;  grid-template-columns: 1fr; width: 100%;
		li.category-title { grid-column: 1; align-self: center;  text-align: center;
			
		}
		li { width: 90%; margin: 0 5%; 
			h1 { font-size: 36px; }
			h3 { font-size: 21px; }
		}
	}

}