.sidebar { width: 25%; float: left; text-align: left; border: 1px solid $border; border-radius: 4px; margin-top: 10px;
	a#mobile-toc { display:none; height: 0; }
	.table-of-contents { width: 100%; margin: 0 auto;
		h4 { font-size: 13px; font-weight: 600; text-transform: uppercase; margin: 30px 13% 10px 13%; }
		hr { background:#fff; margin: 14px auto; }
		ul { list-style: none; margin: 0 0 30px 0; padding: 0;
			li { 
	 			a { font-size: 16px; line-height: 135%; font-weight: 400; padding: 5px 13%; width: 74%; color: $primary-color; display: block; 
					img.stars { display: inline; width: 65px; margin: 0 0 0 2px; outline: none; }
				}
				a:hover,a.active { color: $blue; background: #F5F6FF; }
			}
			li:last-child a { border-bottom: none; }
		}
	}
	a.cta { width: 100%; padding-left: 0px; padding-right: 0px; margin-bottom: 15px; display:none;}
}


@media screen and (max-width:1000px) {

	.sidebar { position: relative; float: none; width: 100%; background: #fff; z-index: 120000; height: 40px; margin-bottom: 0; margin-top: 0; border: 0px;
		
		a#mobile-toc { line-height: 150%; border-bottom: 1px solid $border; color: #333; text-align: center; font-size: 13px; padding: 10px 0; height: 20px; font-weight: 500; display: inline-block; width: 50%;
			img { display: inline; position: relative; left: 2px; top: 2px; width: 12px; height: 12px;  }
		}
		a.active-mobile-toc img { transform: rotate(180deg); }
		.table-of-contents { display: block; text-align:left; display: none; background: #fff; position: absolute; padding: 20px 5%; width: 90%; margin-bottom: 20px; top: 40px; box-shadow: 0 110px 70px 20px rgba(105, 105, 105, 30%); -webkit-box-shadow: 0 110px 70px 20px rgba(105, 105, 105, 30%); -moz-box-shadow: 0 110px 70px 20px rgba(105, 105, 105, 30%);
			ul li a { padding: 5px 2.5%; width: 95%; }
			h4 { margin: 20px 2.5% 10px 2.5%; width: 95%; }
		}
		a.cta { width: 50%; display: inline-block; position: absolute; top: 0; right: 0; height: 20px; padding: 10px 0; border-radius: 0; margin: 0; line-height: 150%; }
	}
	.article-sidebar h4 { display: none; }
	
	.no-cta-sidebar { background: none; text-align: center; height: auto; box-shadow: none; -webkit-box-shadow: none; -moz-box-shadow: none;
		a#mobile-toc { position: relative; top: auto; left: auto; background: #fff; z-index: 120000; border-radius: 0; border: none; border-bottom: 1px solid $border; width: 100%; padding: 10px 0; display: block; }
		.table-of-contents { top: 36px;
			ul { top: 32px;
				li.active ul { display: none; }
			}
		}
	}

	.comparison-sidebar { 
		a.cta:last-of-type { right: auto; left: 0; box-shadow: 0 0 1px 1px #fff; -moz-box-shadow: 0 0 1px 1px #fff; -webkit-box-shadow: 0 0 1px 1px #fff; } 
		a#mobile-toc { display: none; }
	}
	
}